import React, { useEffect, useState } from "react";
import TradeData from "../../components/trade-data/TradeData";
import DashboardStats from "../../components/cm-dashboard-stats/DashboardStats";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function MemberDashboard() {
    const [show, setShow] = useState(false);
    const user = useSelector((state) => state?.user?.user);
    const navigate = useNavigate();

    // useEffect(() => {
    //     if (!user?.onboarding?.subscribed && user?.firstVisit) {
    //         navigate("/member/subscriptionPlans");
    //     } else if (!user?.onboarding?.connect && user?.firstVisit) {
    //         navigate("/member/dashboard");
    //     }
    // }, []);

    useEffect(() => {
        if (user?.role === 'member' && user?.firstVisit) {
            navigate('/member/copyTrade', { replace: true });
        }
    }, [navigate]);

    return (
        // <div className="row justify-content-center">
        //     <div className="col-lg-9 d-grid gap-3 graph_info">
        //         <ul className="row">
        //             <li className="col-8">
        //                 <p className="fs15">PNL</p>
        //                 <h4 className="fs57">0000%</h4>
        //             </li>
        //             <li className="col-4"></li>
        //         </ul>
        //         <ul className="row">
        //             <li className="col-6">
        //                 <p className="text_GM text_dark8">Account balance</p>
        //                 <h4 className="fs20">$0000000.00</h4>
        //             </li>
        //             <li className="col-6">
        //                 <p className="text_GM text_dark8">Last trade</p>
        //                 <h4 className="fs20">$0000000.00</h4>
        //             </li>
        //         </ul>
        //     </div>
        // </div>
        // <div className="trade_data">
        //   <ul className="row">
        //     <li className="col-8">
        //       <section className="trade_data_first_section">
        //         <p className="fs16">PNL</p>
        //         <h4 className="fs57 text-truncate">0000%</h4>
        //       </section>
        //     </li>
        //     <li className="col-4">
        //       <section>
        //         <p className="text-truncate">Account balance</p>
        //         <h4 className="text-truncate">$0000000.00</h4>
        //       </section>
        //       <section>
        //         <p className="text-truncate">Last trade</p>
        //         <h4 className="text-truncate">$0000000.00</h4>
        //       </section>
        //     </li>
        //   </ul>
        // </div>
        <div className="container">
            <div className="row justify-content-center support_wrapper mx-auto h-100">
                <div className="graph_info">
                    {/* {show && (
                    <OnboardingModal
                        noAction={() => {
                            setShow(false);
                            // setSelectedCode();
                        }}
                        heading="Welcome to your dashboard!"
                        description="Some trading is needed 
            to show some stats :)
            Happy copy trading!"
                    />
                )} */}
                    <DashboardStats member={true} />
                </div>
            </div>
        </div>
    );
}

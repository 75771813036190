import React, { useEffect, useState } from "react";
import Select from "react-select";

const options = [
    {
        value: "monthly",
        label: "Monthly",
    },
    {
        value: "yearly",
        label: "Yearly",
    },
];

function OrderSelect({ title, data, setData }) {
    const [value, setValue] = useState();

    const handleChange = (val) => {
        if (val.value === "Yes") {
            setData(true);
        } else {
            setData(false);
        }
    };

    useEffect(() => {
        if (data) {
            setValue({
                value: "Yes",
                label: "Yes",
            });
        } else {
            setValue({
                value: "No",
                label: "No",
            });
        }
    }, [data]);

    return (
        <React.Fragment>
            <div className="select">
                <Select
                    value={value}
                    options={options}
                    onChange={handleChange}
                    isSearchable={false}
                    // menuIsOpen={true}
                    placeholder={title}
                    styles={{
                        singleValue: (base) => ({
                            ...base,
                            display: "flex",
                            alignItems: "center",
                        }),
                    }}
                />
            </div>
        </React.Fragment>
    );
}

export default OrderSelect;

import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Trading from "./Trading";
import TradeHistory from "./TradeHistory";
import { useLocation } from "react-router-dom";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { useSelector, useDispatch } from "react-redux";

function Index() {
    // const [currentTab, setCurrentTab] = useState("trading");
    const { currentTab } = useSelector(state => state.links)
    const { state } = useLocation();
    const dispatch = useDispatch();

    useEffect(() => {
        if (state) {
            dispatch(setCurrentTab(state?.tab));
            window.scrollTo({
                top: 0,
                behavior: "smooth", // Optional: Adds smooth scrolling animation
            });
        }
    }, [state]);

    // useEffect(() => {
    //     dispatch(setCurrentTab("trading"))
    // }, [])

    const handleTabSelect = (selectedTab) => {
        setCurrentTab(selectedTab);
    };

    const handleCurrentTab = (tab) => {
        dispatch(setCurrentTab(tab))
    }

    useEffect(() => {
        console.log({ currentTab })
    }, [currentTab]);

    useEffect(() => {
        if (!["trading", "TradeHistory"].includes(currentTab)) {
            dispatch(setCurrentTab("trading"))
        }

    }, []);

    return (
        <div className="container">
            <div className="">
                <div className="">
                    <Tabs
                        defaultActiveKey="trading"
                        id="uncontrolled-tab-example"
                        className="copyTradeTab PerformanceTab d-none"
                        activeKey={currentTab}
                        onSelect={handleTabSelect}
                    >
                        <Tab
                            eventKey="trading"
                            title=""
                        >
                            <Trading setTab={handleCurrentTab} />
                        </Tab>
                        <Tab
                            eventKey="TradeHistory"
                            title="">
                            <TradeHistory setTab={handleCurrentTab} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Index;

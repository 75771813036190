import React, { useEffect, useLayoutEffect, useState } from "react";
import Stripe from "../../../assets/images/stripe.png";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { setLoading, setShowConnectionFailModal } from "../../../redux/loading/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import DeleteMember from "../../../components/modals/DeleteMember";
import copyIcon from "../../../assets/images/Copy.svg";
import { setShowConnectionSuccessModal } from "../../../redux/loading/loadingSlice";
import { userCommunity } from "../../../redux/community/communitySlice";
import successGif from "../../../assets/images/success.gif";
import errorGif from "../../../assets/images/error.gif";
import paymentOptions from "../../../assets/images/payment-options.svg";
import stripe from "../../../assets/images/stripe.svg";
import threeD from "../../../assets/images/3d-secure.png";
import masterCard from "../../../assets/images/MasterCard.png";
import visa from "../../../assets/images/VisaVerified.png";

import moment from "moment";
import { setCurrentTab } from "../../../redux/links/linksSlice";

function Subscribe({ setTab, setCommunity }) {
    const [activeTab, setActiveTab] = useState("monthly");
    const [selectedIndex, setSelectedIndex] = useState();
    const [selectedCommunity, setSelectedCommunity] = useState();
    const [paymentInfo, setPaymentInfo] = useState();
    const [showModal, setShowModal] = useState(false);
    const [showCancelModal, setShowCancelModal] = useState(false)
    const [paymentSuccessView, setPaymentSuccessView] = useState(false)
    const [paymentFailView, setPaymentFailView] = useState(false)
    const [planSub, setPlanSub] = useState(false);
    const [notShowPaymentDetail, setNotShowPaymentDetail] = useState(false)
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const api = useApi();
    const user = useSelector((state) => state?.user?.user);
    const { id } = useParams();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const sessionId = searchParams.get("session_id");
    // const userVisit = useSelector((state) => state?.user?.user?.firstVisit)

    console.log(user, "UserVisitLast")

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    useEffect(() => {
        // const customerId = localStorage.getItem("customerId")
        // if (customerId === false) {
        //     localStorage.setItem("IscheckOutSession", true)
        //     localStorage.setItem("customerId", true)
        // }

        console.log("1112")
        if (user?.memberCommunities?.length) {
            for (
                let index = 0;
                index < user?.memberCommunities?.length;
                index++
            ) {
                const element = user?.memberCommunities[index];
                if (element?.cryptoPayAddress && !element?.subscribed) {
                    setPaymentInfo({
                        id: element?.cryptoPaymentId,
                        communityId: element?.community?._id,
                        address: element?.cryptoPayAddress,
                        subscriptionId: element?.subscription,
                        interval: element?.planType,
                        price: element?.paidAmount,
                        payableAmount: element?.cryptoPayableAmount,
                    });

                    break;
                }
            }
        }
    }, [user?.memberCommunities]);

    const fetchUserData = async () => {
        console.log("2222")
        const response = await api("get", "users/index");
        if (response?.status) {
            dispatch(storeUser(response?.data));
            localStorage.setItem("token", response?.data?.token);
        }
    };


    const pay = async (subscriptionId, price, communityId) => {
        dispatch(setLoading(true));
        let response = await api("post", "users/payCrypto", {
            price,
            communityId,
            subscriptionId,
            price,
            interval: activeTab,
        });
        dispatch(setLoading(false));

        console.log({ response });

        if (response?.status) {
            await fetchUserData();
            let p = response?.data;
            setPaymentInfo({
                id: p?.payment_id,
                interval: activeTab,
                price,
                communityId,
                address: p?.pay_address,
                subscriptionId,
                payableAmount: p?.pay_amount,
            });
            toastify(
                `Please transfer funds at the following wallet address (${p?.pay_amount} USDT TRC20).`,
                "success"
            );
        } else {
            toastify("Unable to create payment checkout.");
        }
    };

    const unsubscribeCrypto = async () => {
        console.log("theirrr")
        let communityId = selectedCommunity?.community?._id;
        dispatch(setLoading(true));
        // localStorage.setItem('customerId', true);
        let response = await api("post", "users/unsubCrypto", {
            communityId,
        });
        console.log({ response }, "responseOn");
        if (response?.status) {
            setPlanSub(false);
            setShowModal(false);
            setActiveTab(response?.data?.user?.memberCommunities[0]?.planType)
            console.log(response?.data?.user?.memberCommunities[0]?.planType, "inside")
            await fetchUserData();
            toastify("You have been unsubscribed successfully.", "success");
        } else {
            toastify("Unable to unsubscribe");
        }
        dispatch(setLoading(false));
    };

    const cancelPayment = async () => {
        console.log("223333")
        dispatch(setLoading(true))
        // localStorage.setItem('customerId', 'undefined');
        let response = await api("post", "users/cancelCryptoPayment", {
            communityId: paymentInfo?.communityId,
        });

        console.log({ response });

        if (response?.status) {
            await fetchUserData();
            toastify("Crypto payment cancelled successfully.", "success");
            setPaymentInfo();
        } else {
            toastify("Unable to cancel crypto payment");
        }
        dispatch(setLoading(false));
    };

    const checkStatus = async () => {
        console.log("19827272")
        dispatch(setLoading(true));
        let response = await api("post", "users/checkCryptoStatus", {
            paymentId: paymentInfo?.id,
        });

        console.log({ response });

        if (response?.data?.payment_status) {
            let status = response?.data?.payment_status;
            if (status === "failed") {
                setPaymentInfo();
                await cancelPayment();
                toastify("Payment has been failed. Please try again later.");
            } else if (status === "finished") {
                let { interval, price, communityId, subscriptionId } =
                    paymentInfo;
                let subscribe = await api(
                    "post",
                    "users/createCryptoSubscription",
                    {
                        communityId,
                        amount: price,
                        interval,
                        subscriptionId,
                    }
                );
                console.log({ subscribe });
                if (subscribe?.status) {
                    setPlanSub(true);
                    await fetchUserData();
                    setPaymentInfo();
                    toastify("Payment confirmed successfully.", "success");
                    const responseUser = await api("post", "users/update", {
                        ...(user?.firstVisit ? { onboard: "done" } : {}), // Add onboard: "done" only if user.firstVisit is true
                        // firstVisit: false,
                        onboarding: { ...user.onboarding, payment: true },
                        onboardingStep: ""
                    });
                    if (!responseUser?.data.user?.firstVisit) {
                        dispatch(setShowConnectionSuccessModal(true))
                        dispatch(setLoading(false));
                        setPaymentSuccessView(true)
                        setTimeout(() => {
                            navigate("/member/tradeActive", { replace: true })
                        }, 2000);
                    }
                } else {
                    toastify(
                        `Unable to subscribe you for ${activeTab} subscription.`
                    );
                }
            } else {
                toastify(
                    `Payment status is ${status}. Please try again later.`
                );
                dispatch(setLoading(false));
                setPaymentFailView(true)
            }
        }
        dispatch(setLoading(false));
    };

    const createSubscription = async () => {
        console.log("Handle payment call");
        try {
            dispatch(setLoading(true));

            const response = await api("post", "users/handlePayments", {
                sessionId: sessionId,
                communityId: id,
            });
            console.log("Create Subscription:", response);
            if (response?.status) {

                console.log(response?.status, "responseeeStatus")
                setPaymentSuccessView(true)
                console.log("Payment", response);
                dispatch(updateUser(response?.data?.user));
                // dispatch(setLoading(false));
                setPlanSub(true);
                dispatch(setShowConnectionSuccessModal(true))
                const responseUser = await api("post", "users/update", {
                    ...(user?.firstVisit ? { onboard: "done" } : {}), // Add onboard: "done" only if user.firstVisit is true
                    // firstVisit: false,
                    onboarding: { ...user.onboarding, payment: true }
                });
                setActiveTab(responseUser?.data?.user?.memberCommunities[0]?.planType)
                dispatch(updateUser(responseUser?.data?.user))
                console.log(responseUser?.data?.user, "userdata")
                dispatch(setLoading(false));
                if (responseUser?.data.user?.firstVisit) {
                    setTimeout(() => {
                        // setPaymentSuccessView(false)
                        navigate("/member/tradeActive", { replace: true })
                    }, 2000);
                } else {
                    console.log("whyIm working???")
                    setTimeout(() => {
                        setPaymentSuccessView(false)
                        setNotShowPaymentDetail(false)
                        navigate("/member/copyTrade", { replace: true })
                    }, 2000);
                }
                toastify("You have been subscribed successfully.", "success");
            } else {
                // toastify(response?.message);
                dispatch(setLoading(false));
                setPaymentFailView(true)
            }
        } catch (err) {
            console.log(err);
            // toastify("Payment Unsuccessful");
        }
    };

    useEffect(() => {
        console.log("asjkh9229292")
        // dispatch(updateUser(user))
        console.log(user, "ThisIsUser")
        if (id) {
            createSubscription();
        }
        dispatch(setLoading(false));
        if (user?.memberCommunities[0]?.subscribed) {
            setActiveTab(user?.memberCommunities[0]?.planType);
        }
    }, []);

    useLayoutEffect(() => {
        console.log("456789")
        if (id) {
            setNotShowPaymentDetail(true)
        }
    }, [])

    useEffect(() => {
        console.log("this46578")
        user?.memberCommunities?.forEach((com) => {
            let subscriptionId = com?.subscription;
            if (com?.subscribed) {
                setPlanSub(true);
            }

            return com?.community?.subscription?.find((s, i) => {
                if (s?._id === subscriptionId) {
                    setSelectedIndex(i);
                }
            });
        });
    }, [user?.memberCommunities]);

    const handleCheckout = async (com, subscriptionIndex, plan) => {
        console.log("thisiisss")
        console.log("objecttt Is")
        setTab("payment");
        setCommunity({
            communityId: com?.community?._id,
            subscription: com?.community?.subscription[subscriptionIndex],
            plan,
        });
        dispatch(userCommunity({
            communityId: com?.community?._id,
            subscription: com?.community?.subscription[subscriptionIndex],
            plan,
        }))
    };

    const yesAction = async () => {
        console.log("yesActionIsss")
        dispatch(setLoading(true));
        setShowModal(false);
        setShowCancelModal(false)
        const response = await api("post", "users/unsubscribe", {
            sessionId: selectedCommunity?.sessionId,
            communityId: selectedCommunity?.community?._id,
            userId: user._id,
        });
        console.log({ response }, "responseUnsubcribe");

        if (response?.status) {
            dispatch(updateUser(response?.data?.user));
            // setActiveTab("monthly")
            toastify("You have been unsubscribed successfully!", "success");
            console.log(response?.data?.user, "unsubcribeUser")
            setPlanSub(false);
        } else {
            toastify(response?.message);
        }

        dispatch(setLoading(false));
    };

    const noAction = () => {
        setShowModal(false);
    };

    const noActionPayment = () => {
        setShowCancelModal(false);
    };


    const isPlanSubscribed = (com, j, planType) => {
        const subscribedPlan = com?.subscribedPlan || com?.cryptoSubscription;
        const subscriptionId = com?.subscription;

        const matchingSubscription = com?.community?.subscription?.find(
            (subscription) => subscription._id === subscriptionId
        );

        return (
            com?.subscribed &&
            subscribedPlan &&
            matchingSubscription &&
            (matchingSubscription[`${planType}PlanId`] === subscribedPlan ||
                (com?.cryptoSubscription && com?.planType === planType))
        );
    };

    const handlePaymentFailView = async () => {
        // setPaymentFailView(false)
        // setNotShowPaymentDetail(true)
        // navigate('/member/copyTrade');
        const responseUser = await api("post", "users/update");
        setActiveTab(responseUser?.data?.user?.memberCommunities[0]?.planType)
        setTimeout(() => {
            setPaymentFailView(false)
            setNotShowPaymentDetail(false)
            navigate("/member/copyTrade", { replace: true })
        }, 0);
    }

    function getNextMonthSameDate(date) {
        const currentDate = new Date(date);
        currentDate.setMonth(currentDate.getMonth() + 1);
        return currentDate;
    }


    const handleCancelModal = () => {
        setShowCancelModal(true);
    };

    console.log(notShowPaymentDetail, "showpaymentDetal")
    return (
        <React.Fragment>
            {paymentSuccessView ?
                <div className="connectLoader">
                    <div className="text-center">
                        <h3 className="fs20 bold li_h30">Payment successful, <br /> please hold</h3>
                        <p className="fs14 mb-50 date-color-light">Subscription auto-renews every {activeTab}. <br /> Your next billing date is: {moment(getNextMonthSameDate(new Date())).format('DD/MM/YYYY')}</p>
                        <img src={successGif} alt="loaderGif" height={120} />
                    </div>
                </div>
                :
                paymentFailView ?
                    <div className="connectLoader">
                        <div className="text-center">
                            <h3 className="fs20 li_h30 bold">
                                Oops. Something went wrong : &#40;
                            </h3>
                            <p className="fs14 mb-26">Please try again</p>
                            <img src={errorGif} alt="loaderGif" height={248} /> <br />
                            <button style={{ width: "294px" }} className="newCreateButton" onClick={() => { handlePaymentFailView() }}>Try again</button>
                        </div>
                    </div>
                    :
                    !notShowPaymentDetail &&
                    <>
                        {user?.firstVisit &&
                            <div className="text-center">
                                {user?.memberCommunities[0]?.community?.name ? (
                                    <p class="fs18 bold mb-21 text-center">
                                        {user?.memberCommunities[0]?.community?.name?.toUpperCase()}
                                    </p>
                                ) : (
                                    <span className="mb-27 text-light">.</span>
                                )}
                            </div>
                        }
                        <div className="pricing">

                            <div className="d-flex align-items-center justify-content-center">
                                <div className="title">
                                    <h3 className="mb-19 text-center">Choose your subscription</h3>
                                    <ul className="justify-content-center">
                                        <li
                                            className={activeTab === "monthly" ? "active" : ""}
                                            onClick={() => handleTabChange("monthly")}
                                        >
                                            Monthly
                                        </li>
                                        /
                                        <li
                                            className={activeTab === "yearly" ? "active" : ""}
                                            onClick={() => handleTabChange("yearly")}
                                        >
                                            Yearly
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            {paymentInfo ? (
                                <>
                                    <h1 className="fs14 bold mt-2">
                                        Please make payment at the following Pay Address ({" "}
                                        {paymentInfo?.payableAmount} USDT TRC20)
                                    </h1>
                                    <div className="url p-0">
                                        <div className="position-relative d-flex align-items-center justify-content-end copyBlock mt-2">
                                            <input
                                                type="text"
                                                className="form-control fs15"
                                                value={paymentInfo?.address}
                                                disabled
                                            />
                                            <span className="position-absolute me-2">
                                                <img
                                                    className="cursor-pointer me-2"
                                                    onClick={() => {
                                                        const payAddress =
                                                            paymentInfo?.address;

                                                        navigator.clipboard
                                                            .writeText(payAddress)
                                                            .then(() => {
                                                                toastify(
                                                                    "Pay Address copied to clipboard!",
                                                                    "success"
                                                                );
                                                            })
                                                            .catch((error) => {
                                                                console.error(
                                                                    "Clipboard write error:",
                                                                    error
                                                                );
                                                                toastify(
                                                                    "Failed to copy community link to clipboard.",
                                                                    "error"
                                                                );
                                                            });
                                                    }}
                                                    alt="copyIcon"
                                                    src={copyIcon}
                                                />
                                            </span>
                                        </div>
                                    </div>

                                    <div className="d-flex w-100">
                                        <div className="ml-auto">
                                            <button
                                                className="black-btn"
                                                onClick={cancelPayment}
                                            >
                                                Cancel Payment
                                            </button>
                                            <button
                                                className="black-btn ml-20"
                                                onClick={checkStatus}
                                            >
                                                Verify Payment
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div
                                        className={
                                            activeTab === "monthly" ? "monthly" : "d-none"
                                        }
                                    >

                                        <div className="plan-card">
                                            {user?.memberCommunities?.map((com, i) => {
                                                return com?.community?.subscription?.map(
                                                    (subscription, j) => {
                                                        const originalPrice = parseFloat(
                                                            subscription?.monthlyfee
                                                        );
                                                        const newPrice = originalPrice + 15;
                                                        const subscribed = isPlanSubscribed(
                                                            com,
                                                            j,
                                                            "monthly"
                                                        );
                                                        const yearlySubscribed = isPlanSubscribed(
                                                            com,
                                                            j,
                                                            "yearly"
                                                        );

                                                        return (
                                                            <>
                                                                {subscribed &&
                                                                    selectedIndex ===
                                                                    j && (
                                                                        <div className="top-tag">
                                                                            <p>Subscribed</p>
                                                                        </div>
                                                                    )}
                                                                <div className="title-box">

                                                                    <p className="price">
                                                                        ${newPrice}
                                                                        <span>/ month</span>
                                                                    </p>
                                                                </div>
                                                                <span className="line-for-web"></span>
                                                                <div className="list-box">
                                                                    <ul>
                                                                        <li>
                                                                            Unlimited copy
                                                                            trading
                                                                        </li>
                                                                        <li>Dashboard</li>
                                                                        <li>
                                                                            Support 24/7/365
                                                                        </li>
                                                                        <li>
                                                                            Cancel anytime
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <span className="line-for-web"></span>
                                                                <p className="bottom-light-text">
                                                                    Subscription
                                                                    auto-renews every
                                                                    month <br />
                                                                    Next billing date is:
                                                                    <span className="mx-1">
                                                                        {subscribed ? moment(user?.memberCommunities[0]?.subscriptionExpiry).format("DD/MM/YYYY") : moment().add(1, 'month').format("DD/MM/YYYY")}
                                                                    </span>
                                                                </p>
                                                                {(subscribed && selectedIndex === j) ? (
                                                                    <div className="text-center">
                                                                        <button
                                                                            key={j}
                                                                            className="button redBtn medium ms-2 cancel-btn"
                                                                            onClick={() => {
                                                                                setSelectedCommunity(com);
                                                                                setShowModal(true);
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <>

                                                                        <div className="text-center">
                                                                            <button
                                                                                key={j}
                                                                                className={`black-btn button medium ms-2 ${subscribed || yearlySubscribed ? "cursorNotAllowed" : ""}`}
                                                                                onClick={subscribed || yearlySubscribed ? handleCancelModal : () => handleCheckout(com, j, "monthly")}
                                                                            // onClick={() => handleCheckout(com, j, "monthly")}
                                                                            >
                                                                                {`Pay $${newPrice}/month`}
                                                                            </button>
                                                                        </div>
                                                                        {showCancelModal && (
                                                                            <DeleteMember
                                                                                noAction={noActionPayment}
                                                                                yesAction={noActionPayment}
                                                                                text="You’re already subscribed"
                                                                                subtext={`Please cancel current subscription and then subscribe to the ${yearlySubscribed ? "monthly" : "annual"} plan.`}
                                                                                noneclass={"d-none"}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}
                                                            </>
                                                        );
                                                    }
                                                )
                                            }
                                            )}
                                        </div>

                                        {/* monthly payment code */}
                                        {/* <div className="block">
                                        {user?.memberCommunities?.map((com, i) => {
                                            return com?.community?.subscription?.map(
                                                (subscription, j) => {
                                                    const originalPrice = parseFloat(
                                                        subscription?.monthlyfee
                                                    );
                                                    const newPrice = originalPrice + 15;
                                                    const subscribed = isPlanSubscribed(
                                                        com,
                                                        j,
                                                        "monthly"
                                                    );

                                                    return (
                                                        <div
                                                            className={`card ${subscribed &&
                                                                selectedIndex === j
                                                                ? "subscribed"
                                                                : ""
                                                                }`}
                                                            key={j}
                                                        >
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text">
                                                                    <h4 className="m-0">{`$${newPrice} / month`}</h4>
                                                                    {subscription?.tradingCapital ? (
                                                                        <p className="m-0">{`Max. trading Capital $${subscription?.tradingCapital}`}</p>
                                                                    ) : (
                                                                        <p className="m-0">{`Max. trading Capital: Nill`}</p>
                                                                    )}
                                                                </div>
                                                                {subscribed &&
                                                                    selectedIndex === j ? (
                                                                    <div className="redBtn">
                                                                        <button
                                                                            key={j}
                                                                            className="red-btn text_GM px-2 fs13"
                                                                            onClick={() => {
                                                                                setSelectedCommunity(
                                                                                    com
                                                                                );
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            Unsubscribe
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="cryptoBtn">
                                                                            <button
                                                                                key={j}
                                                                                className={`black-btn medium ${!(
                                                                                    subscribed &&
                                                                                    selectedIndex ===
                                                                                    j
                                                                                ) &&
                                                                                    planSub
                                                                                    ? "cursorNotAllowed"
                                                                                    : ""
                                                                                    }`}
                                                                                disabled={
                                                                                    !(
                                                                                        subscribed &&
                                                                                        selectedIndex ===
                                                                                        j
                                                                                    ) &&
                                                                                    planSub
                                                                                }
                                                                                onClick={() =>
                                                                                    pay(
                                                                                        subscription?._id,
                                                                                        parseInt(
                                                                                            subscription?.monthlyfee
                                                                                        ) +
                                                                                        15,
                                                                                        com
                                                                                            ?.community
                                                                                            ?._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Pay with
                                                                                Crypto
                                                                            </button>
                                                                            <button
                                                                                key={j}
                                                                                className={`black-btn medium ms-2 ${!(
                                                                                    subscribed &&
                                                                                    selectedIndex ===
                                                                                    j
                                                                                ) &&
                                                                                    planSub
                                                                                    ? "cursorNotAllowed"
                                                                                    : ""
                                                                                    }`}
                                                                                onClick={() =>
                                                                                    handleCheckout(
                                                                                        com,
                                                                                        j,
                                                                                        "monthly"
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    !(
                                                                                        subscribed &&
                                                                                        selectedIndex ===
                                                                                        j
                                                                                    ) &&
                                                                                    planSub
                                                                                }
                                                                            >
                                                                                Credit Card
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            );
                                        })}
                                    </div> */}

                                        {/* <div className="d-flex align-items-end justify-content-end">
                                        <button
                                            className="black-btn promoteBtn medium"
                                            onClick={() =>
                                                navigate("/member/dashboard")
                                            }
                                        >
                                            Next
                                        </button>
                                    </div> */}
                                    </div>

                                    <div
                                        className={
                                            activeTab === "yearly" ? "yearly" : "d-none"
                                        }
                                    >
                                        <div className="plan-card">
                                            {user?.memberCommunities?.map((com, i) => {
                                                return com?.community?.subscription?.map(
                                                    (subscription, j) => {
                                                        const originalPrice = parseFloat(
                                                            subscription?.yearlyfee
                                                        );
                                                        const newPrice =
                                                            originalPrice + 180;
                                                        const subscribed = isPlanSubscribed(
                                                            com,
                                                            j,
                                                            "yearly"
                                                        );
                                                        const monthlySubscribed = isPlanSubscribed(
                                                            com,
                                                            j,
                                                            "monthly"
                                                        );


                                                        return (
                                                            <>
                                                                {subscribed && selectedIndex ===
                                                                    j && (
                                                                        <div className="top-tag">
                                                                            <p>Subscribed</p>
                                                                        </div>
                                                                    )}
                                                                <div className="title-box">

                                                                    <p className="price">
                                                                        ${newPrice}
                                                                        <span>/ year</span>
                                                                    </p>
                                                                </div>
                                                                <span className="line-for-web"></span>
                                                                <div className="list-box">
                                                                    <ul>
                                                                        <li>
                                                                            Unlimited copy
                                                                            trading
                                                                        </li>
                                                                        <li>Dashboard</li>
                                                                        <li>
                                                                            Support 24/7/365
                                                                        </li>
                                                                        <li>
                                                                            Cancel anytime
                                                                        </li>
                                                                    </ul>
                                                                </div>
                                                                <span className="line-for-web"></span>
                                                                <p className="bottom-light-text">
                                                                    Subscription
                                                                    auto-renews every
                                                                    year <br />
                                                                    Next billing date is:
                                                                    <span className="mx-1">
                                                                        {subscribed
                                                                            ? moment(user?.memberCommunities[0]?.subscriptionExpiry).format("DD/MM/YYYY")
                                                                            : moment().add(1, 'year').format("DD/MM/YYYY")}
                                                                    </span>
                                                                </p>
                                                                {(subscribed && selectedIndex === j) ? (
                                                                    <div className="text-center">
                                                                        <button
                                                                            key={j}
                                                                            className="button redBtn medium ms-2 cancel-btn"
                                                                            onClick={() => {
                                                                                setSelectedCommunity(com);
                                                                                setShowModal(true);
                                                                            }}
                                                                        >
                                                                            Cancel
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="text-center">
                                                                            <button
                                                                                className={`black-btn medium button ms-2 ${subscribed || monthlySubscribed ? "cursorNotAllowed" : ""}`}
                                                                                onClick={subscribed || monthlySubscribed ? handleCancelModal : () => handleCheckout(com, j, "yearly")}
                                                                            // onClick={showModal}
                                                                            // disabled={showModal}
                                                                            >
                                                                                {`Pay $${newPrice}/year`}
                                                                            </button>

                                                                        </div>
                                                                        {showCancelModal && (
                                                                            <DeleteMember
                                                                                noAction={noActionPayment}
                                                                                yesAction={noActionPayment}
                                                                                text="You’re already subscribed"
                                                                                subtext={`Please cancel current subscription and then subscribe to the ${subscribed ? "monthly" : "annual"} plan.`}
                                                                                noneclass={"d-none"}
                                                                            />
                                                                        )}
                                                                    </>
                                                                )}


                                                            </>
                                                        );
                                                    }
                                                )
                                            }
                                            )}
                                        </div>
                                        {/* yearly payment code */}
                                        {/* <div className="block">
                                        {user?.memberCommunities?.map((com, i) => {
                                            return com?.community?.subscription?.map(
                                                (subscription, j) => {
                                                    const originalPrice = parseFloat(
                                                        subscription?.yearlyfee
                                                    );
                                                    const newPrice =
                                                        originalPrice + 180;
                                                    const subscribed = isPlanSubscribed(
                                                        com,
                                                        j,
                                                        "yearly"
                                                    );

                                                    return (
                                                        <div
                                                            className={`card ${subscribed &&
                                                                selectedIndex === j
                                                                ? "subscribed"
                                                                : ""
                                                                }`}
                                                            key={j}
                                                        >
                                                            <div className="d-flex justify-content-between">
                                                                <div className="text">
                                                                    <h4 className="m-0">{`$${newPrice} / year`}</h4>
                                                                    {subscription?.tradingCapital ? (
                                                                        <p className="m-0">{`Max. trading Capital $${subscription?.tradingCapital}`}</p>
                                                                    ) : (
                                                                        <p className="m-0">{`Max. trading Capital: Nill`}</p>
                                                                    )}
                                                                </div>
                                                                {subscribed &&
                                                                    selectedIndex === j ? (
                                                                    <div className="redBtn">
                                                                        <button
                                                                            className="red-btn text_GM px-2 fs13"
                                                                            onClick={() => {
                                                                                setSelectedCommunity(
                                                                                    com
                                                                                );
                                                                                setShowModal(
                                                                                    true
                                                                                );
                                                                            }}
                                                                        >
                                                                            Unsubscribe
                                                                        </button>
                                                                    </div>
                                                                ) : (
                                                                    <>
                                                                        <div className="cryptoBtn">
                                                                            <button
                                                                                key={j}
                                                                                className={`black-btn medium ${!(
                                                                                    subscribed &&
                                                                                    selectedIndex ===
                                                                                    j
                                                                                ) &&
                                                                                    planSub
                                                                                    ? "cursorNotAllowed"
                                                                                    : ""
                                                                                    }`}
                                                                                disabled={
                                                                                    !(
                                                                                        subscribed &&
                                                                                        selectedIndex ===
                                                                                        j
                                                                                    ) &&
                                                                                    planSub
                                                                                }
                                                                                onClick={() =>
                                                                                    pay(
                                                                                        subscription?._id,
                                                                                        parseInt(
                                                                                            subscription?.yearlyfee
                                                                                        ) +
                                                                                        180,
                                                                                        com
                                                                                            ?.community
                                                                                            ?._id
                                                                                    )
                                                                                }
                                                                            >
                                                                                Pay with
                                                                                Crypto
                                                                            </button>
                                                                            <button
                                                                                className={`black-btn medium ms-2 ${!(
                                                                                    subscribed &&
                                                                                    selectedIndex ===
                                                                                    j
                                                                                ) &&
                                                                                    planSub
                                                                                    ? "cursorNotAllowed"
                                                                                    : ""
                                                                                    }`}
                                                                                onClick={() =>
                                                                                    handleCheckout(
                                                                                        com,
                                                                                        j,
                                                                                        "yearly"
                                                                                    )
                                                                                }
                                                                                disabled={
                                                                                    !(
                                                                                        subscribed &&
                                                                                        selectedIndex ===
                                                                                        j
                                                                                    ) &&
                                                                                    planSub
                                                                                }
                                                                            >
                                                                                Credit Card
                                                                            </button>
                                                                        </div>
                                                                    </>
                                                                )}
                                                            </div>
                                                        </div>
                                                    );
                                                }
                                            );
                                        })}
                                    </div> */}

                                        {/* <div className="d-flex align-items-end justify-content-end">
                                        <button
                                            className="black-btn promoteBtn medium"
                                            onClick={() =>
                                                navigate("/member/dashboard")
                                            }
                                        >
                                            Next
                                        </button>
                                    </div> */}
                                    </div>
                                    <div className="text-center mt-3">
                                        <img className="payment-option-img" src={paymentOptions} alt="payment options" />
                                        <p className="secure-payment-text">
                                            Safe and Secure Payments Checkout
                                        </p>
                                        <div className="payment-method-images">
                                            <img className="payment-method-img td-secure" src={threeD} alt="3D Secure" />
                                            <img className="payment-method-img master-cerd" src={masterCard} alt="Master Card" />
                                            <img className="payment-method-img visa-verified" src={visa} alt="visa Verified" />
                                            <img className="payment-method-img stripe" src={stripe} alt="Stripe" />

                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </>
            }
            {showModal && (
                <DeleteMember
                    noAction={noAction}
                    yesAction={() =>
                        selectedCommunity?.cryptoSubscription
                            ? unsubscribeCrypto()
                            : yesAction()
                    }
                    text="Are you sure you want to cancel the subscription?"
                />
            )}
            <RenderLinks page="copyTradePayment" />
        </React.Fragment>
    );
}

export default Subscribe;

import React from "react";
import Check from "../../../assets/images/check.svg";
import { Link } from "react-router-dom";
import RenderLinks from "../../../components/links/RenderLinks";



function AboutApp() {
    const anchorProps = {
        href: 'https://copyyy.it/app',
        target: '_blank',
        style: { color: 'black', textDecoration: 'underline' }
    };


    return (
        <React.Fragment>
            <div className="connection about-app-page">
                <div className="title">
                    <h3>Your own app. Yep, awesome.</h3>
                    <p>
                        We’ll package your service in an app and publish it to
                        the app stores. <br /> We’ll even keep it updated to
                        what ever happens on different devices <br /> and with
                        app store requirements.
                    </p>
                </div>
                <div className="app">
                    <div className="block">
                        <h4 className="fs14">
                            Basic app starts from $69 monthly includes:
                        </h4>
                        <ul className="more">
                            <li>
                                <img src={Check} alt="check" /> Your logo and
                                name
                            </li>
                            <li>
                                <img src={Check} alt="check" /> All the content
                                as website
                            </li>
                            <li>
                                <img src={Check} alt="check" /> Free app updates
                            </li>
                            <li>
                                <img src={Check} alt="check" /> Up to 1000
                                Monthly Active Users
                            </li>
                            <li>
                                <img src={Check} alt="check" /> Published in
                                iOS+Android store
                            </li>
                        </ul>
                    </div>
                    <div className="block" id="container">
                        <h4 className="fs14">
                            You can choose bigger packages with more features
                        </h4>
                        <ul>
                            <li>- More monthly users</li>
                            <li>- Push notifications</li>
                            <li>- Publish app to Amazon + Huawei app stores</li>
                            <li>- App Review Reminders to users</li>
                            <li>- Priority Support</li>
                            <li>
                                - Custom Distribution Outside of App Stores
                                (publish on fx. your own website)
                            </li>
                        </ul>
                    </div>
                    <div className="block">
                        <h4 className="fs14">$499 Onboarding fee</h4>
                        <ul>
                            <li className="m-0">
                                As we’re going to put time into launching your
                                app as we’ll need to customise, design, code,
                                keep track of the process, and publish it to the
                                app stores. And it all takes time and people.
                            </li>
                        </ul>
                    </div>
                    <div className="block">
                        <h4 className="fs14">
                            Submitting your app to the app stores
                        </h4>
                        <ul>
                            <li className="m-0">
                                We handle all the technical aspects of
                                publishing your app and guide you through
                                everything else with detailed step by step
                                guides. However some parts of the publishing
                                process only you can handle as per laws and
                                regulations etc. You can read more about the app
                                store publishing process <strong>
                                    <a {...anchorProps}>here.</a>
                                </strong>

                            </li>
                        </ul>
                    </div>
                    <p className="mt-5 fs12">
                        * Each device the app is opened on counts as 1 user.
                    </p>
                    <p className="fs12">
                        **Custom Distribution Outside of App Stores enables you
                        to publish the app on your website.
                    </p>
                    <RenderLinks page="appAbout" />
                </div>
            </div>
        </React.Fragment>
    );
}

export default AboutApp;

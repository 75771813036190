import React, { useEffect, useState } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import AboutApp from "./AboutApp";
import Pricing from "./Pricing";
import PromoteApp from "./PromoteApp";
import Order from "./Order";
import { useSelector } from "react-redux";
import { setCurrentTab } from "../../../redux/links/linksSlice";
import { useDispatch } from "react-redux";
function Index() {
    // const [currentTab, setCurrentTab] = useState("aboutApp");
    const [order, setOrder] = useState(null);
    const { currentTab } = useSelector(state => state.links)
    const dispatch = useDispatch()

    const handleTabSelect = (selectedTab) => {
        dispatch(setCurrentTab(selectedTab));
    };

    useEffect(() => {
        console.log({ currentTab })
    }, [currentTab]);

    useEffect(() => {
        if (!["aboutApp", "pricing", "promoteApp", "order"].includes(currentTab)) {
            dispatch(setCurrentTab("aboutApp"))
        }

    }, []);
    return (
        <div className="container">
            <div className="row justify-content-center h-100 support_wrapper">
                <div className="copyTrade">
                    <Tabs
                        defaultActiveKey="aboutApp"
                        id="uncontrolled-tab-example"
                        className="copyTradeTab AppTab"
                        activeKey={(currentTab && currentTab !== '') ? currentTab : 'aboutApp'}
                        onSelect={handleTabSelect}
                    >
                        <Tab eventKey="aboutApp"
                        // title="About the app"
                        >
                            <AboutApp />
                        </Tab>
                        <Tab eventKey="pricing"
                        // title="Pricing"
                        >
                            <Pricing
                                setTab={handleTabSelect}
                                setOrder={setOrder}
                            />
                        </Tab>
                        <Tab eventKey="promoteApp"
                        // title="Promote App"
                        >
                            <PromoteApp />
                        </Tab>
                        <Tab eventKey="order"
                        // title="Order"
                        >
                            <Order setTab={handleTabSelect} order={order} />
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default Index;

import React, { useState } from "react";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import { useSelector } from "react-redux/es/hooks/useSelector";

export default function Support() {
    const api = useApi();
    const [message, setMessage] = useState("");
    const [subject, setSubject] = useState("");
    const { user } = useSelector(state => state)

    const sendMessage = async () => {
        if (!subject) {
            toastify("Please enter a subject.");
        } else if (!message) {
            toastify("Please enter a message.");
        } else {
            const { email, role, memberCommunities, leaderCommunity } = user?.user;
            const response = await api("post", "users/sendMessage", {
                message: message.concat(`<br>Sender email: ${email} <br> Community: ${role === "member" ? memberCommunities[0]?.community?.name : leaderCommunity?.name}`),
                subject,
                support: true,
            });
            if (response?.status) {
                setMessage("");
                setSubject("");
                toastify(
                    "Thank you, we’ve received your message and we’ll be in touch with you asap.",
                    "success"
                );
            } else {
                toastify(response?.message);
            }
        }
    };

    return (
        <div className="container mt-sm-30">
            <div className="row justify-content-center h-100 support_wrapper">
                <div className="copyTrade mt-30">
                    <span className="topLines"></span>
                    <div className="connection">
                        <div className="memberMessage position-relative">
                            <div className="mx-554">
                                <h2 className="mb-46">Message support</h2>

                                <div className="messageInput">
                                    <div className="form-floating">
                                        <input
                                            type="text"
                                            className="form-control"
                                            id="subject"
                                            placeholder=""
                                            value={subject}
                                            onChange={(e) =>
                                                setSubject(e.target.value)
                                            }
                                        />
                                        <label for="subject">Subject</label>
                                    </div>
                                </div>
                                <div className="messageInput">
                                    <textarea
                                        placeholder="Type your message here"
                                        rows="11"
                                        value={message}
                                        onChange={(e) =>
                                            setMessage(e.target.value)
                                        }
                                    />
                                </div>
                                <div className="d-flex justify-content-end mt-41 pb-5 pb-lg-0 pb-md-0">
                                    <button
                                        className="black-btn promoteBtn text_GM"
                                        onClick={sendMessage}
                                    >
                                        Send
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="articleRelatives">
                        <RenderLinks page="support" />
                    </div>
                </div>
            </div>
        </div>
    );
}

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    token: null,
    user: null,
};

export const userSlice = createSlice({
    name: "userSlice",
    initialState,
    reducers: {
        storeUser: (state, action) => {
            state.user = action.payload.user;
            state.token = action.payload.token;
        },
        updateUser: (state, action) => {
            state.user = action.payload;
        },
        setEditable: (state, action) => {
            state.editable = action.payload;
        },
    },
});

export const { storeUser, updateUser, setEditable } = userSlice.actions;

export default userSlice.reducer;

import React, { useEffect, useState } from "react";
import closeSuccessIcon from "../../assets/images/close_success.svg";
import { useNavigate } from "react-router-dom";
import useApi from "../../helpers/apiHelper/requestHelper";
import { toastify } from "../../helpers/toast/toastify";
import { missingPopup } from "../../helpers/dataHelper/missingData";
import Footer from "../../components/footer/Footer";
import Header from "../../components/Header";
import { useSelector } from "react-redux";
import Button from "../../components/button/Button";
import InputComponent from "../../components/input/InputComponent";

export default function ForgotPassword() {
    const api = useApi();
    const navigate = useNavigate();
    const [email, setEmail] = useState("");
    const [focusedInput, setFocusedInput] = useState(null);
    const user = useSelector((state) => state?.user?.user);

    useEffect(() => {
        if (user) {
            if (user?.role === "leader") navigate("/leader/dashboard");
            else if (user?.role === "member") navigate("/member/dashboard", { replace: true });
            else if (user?.role === "admin") navigate("/admin/dashboard");
        }
    }, [window.location, user]);

    const resetPassword = async () => {
        if (!email) {
            missingPopup("Email");
        } else {
            const response = await api("post", "auth/forgotemail", { email });
            console.log({ response });
            if (response?.status) {
                // navigate("/login/verifyEmail", {
                //     state: { resetPass: true, email },
                // });
                navigate("/login");
                toastify("Reset link has been sent to your email.", "success");
            } else {
                toastify(response?.message);
            }
        }
    };

    const handleFocus = (inputName) => {
        setFocusedInput(inputName);
    };

    return (
        <div className="common_style">
            <Header />

            <section className="sign_section d-flex align-items-center">
                <div className="login_card verify_card account_card">
                    <div className="container">
                        <div className="row justify-content-center">
                            <div className="col-sm-12 px-0">
                                <div className="">
                                    <h2 className="fs20 text_GB mb-9">
                                        Reset password
                                    </h2>
                                    <div className="mb-26 height-14px"></div>
                                </div>
                                <div className="pt-1">
                                    <InputComponent
                                        type="email"
                                        label="Your email"
                                        focusedInput={email ? "focused" : ""}
                                        handleFocus={handleFocus}
                                        value={email}
                                        onChange={setEmail}
                                    />
                                </div>
                                <div className="mt-19">
                                    <div className="d-flex justify-content-end">
                                        <button
                                            onClick={resetPassword}
                                            className="newCreateButton"
                                        >
                                            Reset Password
                                        </button>
                                    </div>
                                    {/* <button
                                        className="btn btn-success radius min_h57 w-100 fs20"
                                        data-bs-toggle="modal"
                                        data-bs-target="#RESETPASSWORD"
                                        role="button"
                                        onClick={() => resetPassword()}
                                        >
                                        RESET PASSWORD
                                        </button> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <div
                className="modal fade"
                id="RESETPASSWORD"
                tabIndex="-1"
                aria-labelledby="RESETPASSWORDLabel"
                aria-hidden="true"
            >
                <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                        <div className="modal-body position-relative">
                            <img
                                src={closeSuccessIcon}
                                alt="img"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                                className="position-absolute"
                                role="button"
                            />

                            <div className="ps-5">
                                <p className="fs16 text-700">Email sent!</p>
                                <p className="fs16">
                                    Follow the link in that email!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </div>
    );
}

import { combineReducers, configureStore } from "@reduxjs/toolkit";
import usersSlice from "../redux/users/usersSlice";

import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import thunk from "redux-thunk";
import loadingSlice from "../redux/loading/loadingSlice";
import sidebarSlice from "../redux/sidebar/sidebarSlice";
import linksSlice from "../redux/links/linksSlice";
import communitySlice from "../redux/community/communitySlice";
import socialSignUpSlice from "../redux/socialSignup/socialSignUp";

const persistConfig = {
    key: "root",
    storage,
};

const rootReducer = combineReducers({
    user: usersSlice,
    loader: loadingSlice,
    sidebar: sidebarSlice,
    links: linksSlice,
    community: communitySlice,
    socialSignUp: socialSignUpSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    middleware: [thunk],
});

export const persistor = persistStore(store);

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    allLinks: [],
    currentTab: "connection"
};

export const linksSlice = createSlice({
    name: "linksSlice",
    initialState,
    reducers: {
        setAllLinks: (state, action) => {
            state.allLinks = action.payload;
        },
        setCurrentTab: (state, action) => {
            state.currentTab = action.payload;
        },
    },
});

export const { setAllLinks, setCurrentTab } = linksSlice.actions;

export default linksSlice.reducer;

import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Cross from "../../../assets/images/cross.svg";
import { useTour } from "@reactour/tour";
import OnboardingModal from "../../../components/modals/OnboardingModal";
import { Link, useNavigate, useParams } from "react-router-dom";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { updateUser, setEditable } from "../../../redux/users/usersSlice";
import { toastify } from "../../../helpers/toast/toastify";
import AddIcon from "../../../assets/images/addIcon.svg";
import ExchangeAccounts from "./ExchangeAccounts";
import RenderLinks from "../../../components/links/RenderLinks";
import closeIcon from "../../../assets/images/cancelBlack.svg";
import editIcon from "../../../assets/images/Pen.svg"

function Subscription({ setTab, tab }) {
    const api = useApi();
    const user = useSelector((value) => value?.user?.user);
    const inputRef = useRef(null);

    // useEffect(() => {
    //     if (inputRef.current) {
    //     inputRef?.current?.focus();
    //     }
    // }, []);

    //     useEffect(() => {
    //     const input = inputRef.current;
    //     if (input) {
    //       input.focus();

    //       // Ensure the keyboard opens on mobile devices
    //       const touchStartHandler = () => {
    //         input.focus();
    //         input.removeEventListener('touchstart', touchStartHandler);
    //       };
    //       input.addEventListener('touchstart', touchStartHandler);
    //     }
    //   }, []);

    const [subscription, setSubscription] = useState([
        {
            name: `${user?.leaderCommunity?.name} Subscription Tier 1`,
            monthlyfee: "",
            yearlyfee: "",
            tradingCapital: "",
        },
    ]);
    // const [pair, setPair] = useState([]);
    const [estimatedAmount, setEstimatedAmount] = useState("");
    const [show, setShow] = useState(false);
    const editable = useSelector((value) => value?.user?.editable);
    // const [editable, setEditable] = useState(true)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const numberRegex = new RegExp(`^[0-9]*$`);
    const [tagData, setTagData] = useState([]);

    const handleAddTier = () => {
        setSubscription((prevSubscription) => [
            ...prevSubscription,
            {
                name: `${user?.leaderCommunity?.name} Subscription Tier ${prevSubscription.length + 1
                    }`,
                monthlyfee: "",
                yearlyfee: "",
                tradingCapital: "",
            },
        ]);
    };

    const handleDeleteTier = (index) => {
        setSubscription((prevSubscription) => {
            // Create a copy of the current subscription array
            const newSubscription = [...prevSubscription];

            // Remove the tier at the specified index
            newSubscription.splice(index, 1);

            // Update the state with the modified array
            setSubscription(newSubscription);

            return newSubscription;
        });
    };

    useEffect(() => {
        if (user?.leaderCommunity) {
            if (user?.leaderCommunity?.subscription?.length > 0) {
                setSubscription(user?.leaderCommunity?.subscription);
            }
            setEstimatedAmount(user?.leaderCommunity?.estimatedAmount || "");
            setTagData(user?.leaderCommunity?.pair || []);
        }
    }, [user]);

    const removeTagData = (indexToRemove) => {
        setTagData([...tagData.filter((_, index) => index !== indexToRemove)]);
    };
    const addTagData = (event) => {
        if (event.target.value !== "") {
            setTagData([...tagData, event.target.value]);
            event.target.value = "";
        }
    };

    useEffect(() => {
        if (user?.firstVisit) {
            setShow(true);
        }
    }, [user]);

    // useEffect(() => {
    //     document.addEventListener('click', () => {
    //         if (tab === "subscription") {
    //             debugger
    //             return toastify("Please complete your onboarding", "error");
    //         }
    //     });
    //     return () => {
    //         document.removeEventListener('click', () => {
    //             console.log("hello")
    //         });
    //     };
    // }, []);

    // const handleClick = (event) => {
    //     if (user?.firstVisit && tab === "subscription") {
    //         toastify("Please complete your onboarding", "error");
    //     }
    // };


    const handleInputChange = (value, key, tierIndex) => {
        setSubscription((prevSubscription) => {
            const newValues = [...prevSubscription];

            if (!newValues[tierIndex]) {
                newValues[tierIndex] = {
                    name: "",
                    monthlyfee: "",
                    yearlyfee: "",
                    tradingCapital: "",
                };
            } else if (key === "pair") {
                console.log("Pair", key, value);
                newValues[tierIndex] = {
                    ...newValues[tierIndex],
                    pair: [...newValues[tierIndex].pair, value],
                };
            } else {
                newValues[tierIndex] = {
                    ...newValues[tierIndex],
                    [key]: value,
                };
            }
            return newValues;
        });
    };

    const submit = async () => {
        dispatch(setLoading(true));
        dispatch(setEditable(true))
        // Validate input fields
        // const isValid = subscription.every((tier) => {
        //     return (
        //         tier.name &&
        //         tier.monthlyfee !== undefined &&
        //         tier.yearlyfee !== undefined &&
        //         tier.tradingCapital !== undefined
        //     );
        // });

        if (
            !subscription.length > 0
            // ||
            // !tagData.length > 0
            // estimatedAmount === ""
        ) {
            toastify("Please fill in all required fields.", "error");
            dispatch(setLoading(false));
            return;
        }

        // Format the numeric fields to remove leading zeros
        const formattedSubscription = subscription.map((tier) => ({
            ...tier,
            monthlyfee: tier.monthlyfee ? parseInt(tier.monthlyfee, 10).toString() : tier.monthlyfee,
            yearlyfee: tier.yearlyfee ? parseInt(tier.yearlyfee, 10).toString() : tier.yearlyfee,
            tradingCapital: tier.tradingCapital ? parseInt(tier.tradingCapital, 10).toString() : tier.tradingCapital,
        }));

        // Filter out undefined objects from the subscription array
        const filteredSubscription = formattedSubscription.filter((tier) => tier.name);

        // Prepare the data to send to the backend
        const updatedSubscription = {
            subscription: filteredSubscription,
            pair: tagData, // Assuming tagData is an array
            // estimatedAmount,
        };

        console.log("updatedSubscription", updatedSubscription);

        try {
            // Make API call to send data to the backend
            const response = await api("post", "users/submitSubscription", {
                updatedSubscription,
            });

            if (response?.status) {
                // Update user data and handle other logic
                const response2 = await api("get", "users/index");
                if (response2) {
                    dispatch(updateUser(response2?.data?.user));
                    const { onboarding, firstVisit } = response2?.data?.user
                    if (onboarding?.subscribed && firstVisit) {
                        toastify("Subscription added successfully.", "success");
                        setTab("connection");
                        await api("post", "users/update", {
                            onboardingStep: "connection"
                        });
                    } else if (!firstVisit) {
                        toastify("Subscription updated successfully.", "success");
                    }
                }
            } else {
                toastify(response?.message);
                console.log(response?.message);
            }
        } catch (error) {
            console.error("Error submitting subscription:", error);
            toastify(
                "An error occurred while submitting subscription.",
                "error"
            );
        }

        dispatch(setLoading(false));
    };

    const handleEdit = () => {
        dispatch(setEditable(false))
        // Use setTimeout to ensure that the input is focused before selecting text
        // Create a temporary input element to force the keyboard to open on iOS
        let userAgent = navigator?.userAgent;
        let tempInput = null
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input"
            tempInput.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (inputRef?.current) {
                inputRef?.current?.focus();
            }
        }, 0);

        console.log(editable, "editable");
    };



    return (
        <div className="subscription subscriptionJs">
            {/* {show && (
                <OnboardingModal
                    noAction={() => {
                        setShow(false);
                    }}
                    heading={
                        <>
                            Ok, let’s get you set up. <br />
                            Please complete each of the sections above starting
                            with this section.
                        </>
                    }
                    handleClick={() => { toastify("Please complete your onboarding") }}
                />
            )} */}
            <div className="title">
                <h3>Subscription pricing</h3>

                <p>
                    To launch your copy trade you need to define how much you want to charge your users on a monthly basis and on a yearly basis.
                    <br />
                    <br />Please note that we’ll be adding $15 to the amount you want to charge your members.
                </p>
            </div>
            <div className="tier mx-691">
                <h3>Pricing</h3>
                {subscription.map((tier, index) => (
                    <>
                        <div
                            className="card subsCard mb-3 card-container position-relative"
                            key={index}
                        >
                            {index > 0 && (
                                <img
                                    src={closeIcon}
                                    alt="Delete"
                                    className="subclose-icon cursor-pointer"
                                    onClick={() => handleDeleteTier(index)}
                                />
                            )}
                            <div className="block">
                                <div className="form-floating">
                                    <input
                                        ref={inputRef}
                                        type="text"
                                        autoComplete="new-password"
                                        placeholder="$"
                                        disabled={user?.firstVisit ? false : editable}
                                        className="form-control"
                                        id={`floatingInput_${index}`} // Use a unique ID for each input
                                        value={
                                            tier?.monthlyfee !== undefined
                                                ? tier?.monthlyfee === ""
                                                    ? ""
                                                    : `$${tier?.monthlyfee}`
                                                : ""
                                        }
                                        onChange={(val) => {
                                            console.log("valueInsidee", val.target.value)
                                            if (val.target) {
                                                const inputWithoutDollar =
                                                    val.target.value.replace(
                                                        /^\$/,
                                                        ""
                                                    );
                                                const isValidNumber =
                                                    numberRegex.test(
                                                        inputWithoutDollar
                                                    ) ||
                                                    inputWithoutDollar === "";

                                                if (
                                                    isValidNumber &&
                                                    inputWithoutDollar.length <=
                                                    5
                                                ) {
                                                    handleInputChange(
                                                        inputWithoutDollar,
                                                        "monthlyfee",
                                                        index
                                                    );
                                                }
                                            }
                                        }}
                                    />
                                    <label htmlFor={`floatingInput_${index}`}>
                                        Monthly fee
                                    </label>
                                </div>

                                <span>
                                    Subs will pay: $
                                    {tier?.monthlyfee &&
                                        parseInt(tier?.monthlyfee) + 15}
                                </span>
                            </div>
                            <div className="block">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        placeholder="$"
                                        className="form-control"
                                        autoComplete="new-password"
                                        disabled={user?.firstVisit ? false : editable}
                                        id={`floatingInput_${index + 1}`} // Use a unique ID for each input
                                        value={
                                            tier?.yearlyfee !== undefined
                                                ? tier?.yearlyfee === ""
                                                    ? ""
                                                    : `$${tier?.yearlyfee}`
                                                : ""
                                        }
                                        onChange={(val) => {
                                            const inputWithoutDollar =
                                                val.target.value.replace(
                                                    /^\$/,
                                                    ""
                                                );
                                            const isValidNumber =
                                                numberRegex.test(
                                                    inputWithoutDollar
                                                ) || inputWithoutDollar === "";

                                            if (
                                                isValidNumber &&
                                                inputWithoutDollar.length <= 5
                                            ) {
                                                handleInputChange(
                                                    inputWithoutDollar,
                                                    "yearlyfee",
                                                    index
                                                );
                                            }
                                        }}
                                    />
                                    <label
                                        htmlFor={`floatingInput_${index + 1}`}
                                    >
                                        Yearly fee
                                    </label>
                                </div>

                                <span>
                                    Subs will pay: $
                                    {tier?.yearlyfee &&
                                        parseInt(tier?.yearlyfee) + 180}
                                </span>
                            </div>
                            {!user?.firstVisit &&
                                <div className="block edit-button-block">
                                    <button type="button" className="edit-button-subscription ms-auto me-n-1" onClick={handleEdit}>
                                        <img className="" src={editIcon} alt="" />
                                    </button>
                                </div>
                            }
                            {/* <div className="block">
                                <div className="form-floating">
                                    <input
                                        type="text"
                                        placeholder="$"
                                        className="form-control"
                                        id={`floatingInput_${index + 2}`} // Use a unique ID for each input
                                        value={
                                            tier?.tradingCapital !== undefined
                                                ? tier?.tradingCapital === ""
                                                    ? ""
                                                    : `$${tier?.tradingCapital}`
                                                : ""
                                        }
                                        onChange={(val) => {
                                            const inputWithoutDollar =
                                                val.target.value.replace(
                                                    /^\$/,
                                                    ""
                                                );
                                            const enteredValue =
                                                parseFloat(inputWithoutDollar);
                                            const isValidNumber =
                                                numberRegex.test(
                                                    inputWithoutDollar
                                                ) || inputWithoutDollar === "";

                                            if (
                                                (isValidNumber &&
                                                    enteredValue <= 1000000) ||
                                                inputWithoutDollar === ""
                                            ) {
                                                handleInputChange(
                                                    inputWithoutDollar,
                                                    "tradingCapital",
                                                    index
                                                );
                                            }
                                        }}
                                    />

                                    <label
                                        htmlFor={`floatingInput_${index + 2}`}
                                    >
                                        Max. trading capital
                                    </label>
                                </div>

                                <span>(No input = unlimited)</span>
                            </div> */}
                        </div>
                    </>
                ))}

                {/* <div className="addTier">
                    <img src={AddIcon} alt="addicon" onClick={handleAddTier} />
                    <p>Add extra tier</p>
                </div> */}

                {/* <h3>2. What are you mainly trading?</h3>
                <div className="card altCard">
                    <div className="block">
                        <label>Fx. ALTs/USDT+enter</label>
                        <div className="tag-input">
                            <ul className="tags">
                                {tagData?.map((tag, index) => (
                                    <li key={index} className="tag">
                                        <span className="tag-title">{tag}</span>
                                        <span
                                            className="tag-close-icon"
                                            onClick={() => removeTagData(index)}
                                        >
                                            <img src={Cross} alt="cross" />
                                        </span>
                                    </li>
                                ))}
                            </ul>
                            <input
                                type="text"
                                className="fs15"
                                onKeyUp={(event) =>
                                    event.key === "Enter"
                                        ? addTagData(event)
                                        : null
                                }
                                // placeholder="Press add a currency"
                                onChange={(val) => {
                                    handleInputChange(val.target.value, "pair");
                                }}
                            />
                        </div>
                    </div>
                </div> */}

                {/* <h3>3. Last 90 days trading would have turned $1000 into?</h3>
                <div className="card">
                    <div className="block">
                        <div className="form-floating">
                            <input
                                type="text"
                                placeholder="$"
                                className="form-control"
                                id="floatApprox"
                                value={
                                    estimatedAmount !== undefined
                                        ? estimatedAmount === ""
                                            ? ""
                                            : `$${estimatedAmount}`
                                        : ""
                                }
                                onChange={(val) => {
                                    const inputWithoutDollar =
                                        val.target.value.replace(/^\$/, ""); // Remove $ sign if present
                                    const isValidNumber =
                                        numberRegex.test(inputWithoutDollar) ||
                                        inputWithoutDollar === "";

                                    if (isValidNumber) {
                                        setEstimatedAmount(inputWithoutDollar);
                                    }
                                }}
                            />
                            <label htmlFor="floatApprox">
                                Approx result in USD
                            </label>
                        </div>
                        <span className="color-transparent">.</span>
                    </div>
                </div> */}

                {user?.firstVisit && editable &&
                    <div className="d-flex justify-content-end mt-5">
                        <button className="btn cyan-btn fs13" onClick={submit}>
                            {user?.firstVisit ? "Next" : "Save Changes"}
                        </button>
                    </div>
                }
                {!editable &&
                    <div className="d-flex justify-content-end mt-5">
                        <button className="btn cyan-btn fs13" onClick={submit}>
                            {user?.firstVisit ? "Next" : "Save Changes"}
                        </button>
                    </div>
                }
            </div>
            <RenderLinks page="copyTradeSubscription" />
        </div>
    );
}

export default Subscription;

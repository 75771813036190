import React, { useState, useEffect, useLayoutEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout,
} from "@stripe/react-stripe-js";
import { useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

function Payment({ setTab, community }) {
    const [clientSecret, setClientSecret] = useState("");
    const [load, setLoad] = useState(true);
    const dispatch = useDispatch();
    const { id } = useParams();
    const api = useApi();

    const [communityUser, setCommunityUser] = useState({});
    useEffect(() => {
        const createCheckout = async () => {
            dispatch(setLoading(true));
            try {
                const response = await api(
                    "post",
                    "users/checkoutSession",
                    {
                        communityId: community?.communityId,
                        subscription: community?.subscription,
                        plan: community?.plan,
                    }
                );

                console.log("Checkout response:", response);
                // localStorage.setItem('customerId', 'undefined');
                // localStorage.setItem('customerId', true);
                // localStorage.setItem('IscheckOutSession', false);


                if (response?.status) {
                    setClientSecret(response?.data?.clientSecret);
                    dispatch(setLoading(false));
                    setLoad(false);
                    console.log("Client secret:", response?.data?.clientSecret);
                } else {
                    toastify(response?.message);
                    dispatch(setLoading(false));
                    console.log("elseTry")
                }
            } catch (error) {
                dispatch(setLoading(false));
                console.error("Error creating checkout session:", error);
                console.log("elseCatch")
            }
        };


        console.log("beforeFetchdaat")

        const fetchData = async () => {
            try {
                const response = await api("get", "users/index");
                console.log("indexUser", response);
                setCommunityUser(response);
            } catch (error) {
                console.log(error, "this is error");
            }
        };

        const executeSequentially = async () => {
            const storedCustomerId = localStorage.getItem('customerId');
            const IscheckOutSession = localStorage.getItem('IscheckOutSession');

            if (!id) {
                // if (storedCustomerId === null && IscheckOutSession === null) {
                //     await createCheckout();
                // } else if (storedCustomerId && IscheckOutSession) {
                //     console.log("myCondition, True True")
                //     await createCheckout();
                // }
                await createCheckout();
            }

            fetchData()// Delay fetchData by 2 seconds after createCheckout

            console.log(storedCustomerId, "======storedCustomerId")
        };

        executeSequentially();


    }, []);

    // useEffect(() => {
    //     if (communityUser?.data?.user?.memberCommunities[0]?.customerId) {
    //         localStorage.setItem('customerId', communityUser?.data?.user?.memberCommunities[0]?.customerId);
    //     }
    // }, [community]);

    console.log(communityUser?.data?.user?.memberCommunities[0]?.customerId, "dataaa");

    const appearance = {
        theme: "stripe",
    };
    const options = {
        clientSecret,
        appearance,
    };

    return (
        <div id="checkout">
            {clientSecret && (
                <EmbeddedCheckoutProvider
                    stripe={stripePromise}
                    options={{ clientSecret }}
                >
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            )}
        </div>
    );
}

export default Payment;

import React, { useEffect, useState } from "react";
import DashboardStats from "../../components/dashboard-stats/DashboardStats";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

export default function LeaderDashboard() {
    const user = useSelector((state) => state?.user?.user);
    const navigate = useNavigate();

    // useEffect(() => {

    //     if (!user?.onboarding?.subscribed && user?.firstVisit) {
    //         navigate("/leader/subscriptionPlans");
    //     } else if (!user?.onboarding?.connect && user?.firstVisit) {
    //         navigate("/leader/connectExchange");
    //     }
    // }, []);

    return (
        <div className="container">
            <div className="row justify-content-center support_wrapper w-100 px-0 mx-auto h-100">
                <div className="col-xl-8 col-lg-12 mx-auto g-0">
                <div className="graph_info">
                    {/* {show && (
                    <OnboardingModal
                        noAction={() => {
                            setShow(false);
                        }}
                        heading="Welcome to your dashboard!"
                        description="Some trading is needed 
            to show some stats :)
            Happy copy trading!"
                    />
                )} */}
                    <DashboardStats />
                </div>
                </div>
            </div>
        </div>
    );
}

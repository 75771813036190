import React, { useEffect, useRef, useState } from "react";
import Binance from "../../../assets/images/Binance_black.png";
import BayBit from "../../../assets/images/Bybit_black.png";
import Okx from "../../../assets/images/okx.jpg";
import Kucoin from "../../../assets/images/kucoin.jpg";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import {
    setLoading,
    setResponseLoading,
    setShowConnectionSuccessModal,
    setShowConnectionFailModal,
    setConnectionFailMessage,
    setBinanceConnecting
} from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import { updateUser } from "../../../redux/users/usersSlice";
import ExchangeConnects from "./ExchangeConnects";
import copyIcon from "../../../assets/images/Copy.svg";
import { missingPopup } from "../../../helpers/dataHelper/missingData";
import RenderLinks from "../../../components/links/RenderLinks";
import AuthLoader from "../../../components/loader/authLoader";
import DeleteMember from "../../../components/modals/DeleteMember";
import { setCurrentTab } from "../../../redux/links/linksSlice";

function Connection({ setTab }) {
    const user = useSelector((value) => value?.user?.user);
    const [showConnection, setShowConnection] = useState(true);
    const [view, setView] = useState("connect");
    const [callSubmit, setCallSubmit] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [scroll, setScroll] = useState(false);
    const [credentials, setCredentials] = useState({
        exchange: "",
        apiKey: "",
        secretKey: "",
    });
    const [exchangeName, setExchangeName] = useState("");
    const [selectedConnectionType, setSelectedConnectionType] = useState("")
    const dispatch = useDispatch();
    const api = useApi();
    const loaders = useSelector((state) => state?.loader);
    const inputRef = useRef(null);


    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setScroll(false);
    }, [scroll]);

    // useEffect(() => {
    //     if (loaders?.awaitingResponse) {
    //         const timeoutId = setTimeout(() => {
    //             dispatch(setResponseLoading(false));
    //         }, 3000);

    //         // Cleanup the timeout to avoid potential memory leaks
    //         return () => clearTimeout(timeoutId);
    //     }
    // }, [loaders?.awaitingResponse]);

    // const [liveUrl, setLiveUrl] = useState(true)
    const liveUrl = true

    const oauthBinance = async () => {
        if (
            user?.binanceCredentials?.apiKey &&
            user?.binanceCredentials?.secretKey
        ) {
            getUserCredentials("Binance", "callSubmit");
        } else {
            let userAgent = navigator?.userAgent;
            if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
                console.log("Safari-Browser");
                dispatch(setLoading(false))
            } else {
                dispatch(setLoading(true))
            }
            dispatch(setBinanceConnecting(true))
            const clientId = "39vZ9un1Fd";
            const redirect_uri = liveUrl ? "https%3A%2F%2Fmy.copyyy.it%2Fsignin" : "https%3A%2F%2Fdev.copyyy.it%2Fsignin"
            // const redirect_uri = "https%3A%2F%2Fdev.copyyy.it%2Fsignin";
            const scope = "user:openId,create:apikey";
            const cUrl = `https://accounts.binance.com/en/oauth/authorize?response_type=code&client_id=${clientId}&redirect_uri=${redirect_uri}&scope=${scope}`;
            window.location.href = cUrl;
        }
    };

    const oauth = async () => {
        if (
            user?.bybitCredentials?.apiKey &&
            user?.bybitCredentials?.secretKey
        ) {
            getUserCredentials("ByBit", "callSubmit");
        } else {
            let userAgent = navigator?.userAgent;
            if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
                console.log("Safari-Browser");
                dispatch(setLoading(false))
            } else {
                dispatch(setLoading(true))
            }

            localStorage.setItem("binanceCondition", true)

            const clientId = "3ccf41551815af4";
            console.log("client id", clientId);
            const cUrl = `https://www.bybit.com/oauth?client_id=${clientId}&response_type=code&scope=openapi&state=123abc`;
            window.location.href = cUrl;
        }
    };

    const getUserCredentials = async (event, hint) => {
        console.log({ hint });
        let exchangeKey = "";
        if (event === "Binance") {
            exchangeKey = "binance";
        } else if (event === "ByBit") {
            exchangeKey = "bybit";
        }

        if (
            (event === "ByBit" && !user?.bybitCredentials?.apiKey) ||
            (event === "Binance" && !user?.binanceCredentials?.apiKey)
        ) {
            // No credentials available for the selected exchange, don't call the API
            setCredentials((prev) => ({
                ...prev,
                apiKey: "",
                secretKey: "",
                exchange: event,
            }));
        } else {
            // Credentials are available, update the exchange and call the API
            const updatedCredentials = {
                ...credentials,
                exchange: event,
            };
            setCredentials(updatedCredentials);

            dispatch(setLoading(true));
            const response = await api(
                "get",
                `binanceCredentials/index/${exchangeKey}`
            );

            console.log("Response:", response);
            if (response?.status) {
                let data = response?.data;
                if (data?.length === 3) {
                    setCredentials({
                        apiKey: data?.apiKey,
                        secretKey: data?.secretKey,
                        // secretPhrase: data?.secretPhrase,
                        exchange: data?.exchange,
                    });
                    if (hint === "callSubmit") {
                        setCallSubmit(true);
                    }
                } else {
                    setCredentials({
                        apiKey: data?.apiKey,
                        secretKey: data?.secretKey,
                        exchange: data?.exchange,
                    });
                    if (hint === "callSubmit") {
                        setCallSubmit(true);
                    }
                }

            } else {
                setCredentials({
                    apiKey: "",
                    secretKey: "",
                    exchange: event,
                });
            }
        }
        dispatch(setLoading(false))
    };

    // useEffect(() => {
    //     dispatch(setResponseLoading(true));
    // }, []);

    useEffect(() => {
        if (callSubmit) {
            console.log("Call submit");
            submit();
        }
    }, [callSubmit]);

    const switchToApiKeyView = () => {
        setScroll(true);
        setSelectedConnectionType("")
        setShowConnection((prevShowConnection) => !prevShowConnection);
        setView((prevView) => (prevView === "connect" ? "apiKeys" : "connect"));
        setCredentials({
            apiKey: "",
            secretKey: "",
            exchange: "",
        });
    };

    const handleNavigate = () => {
        if (user?.onboarding?.connect) {
            setTab("payment");
        }
    };

    const noAction = () => {
        setShowModal(false);
    };

    const submit = async () => {
        console.log("SUBMIT CALLED");
        dispatch(setResponseLoading(true));

        if (!credentials?.exchange) {
            dispatch(setResponseLoading(false));
            missingPopup("Exchange");
        } else if (!credentials?.apiKey) {
            dispatch(setResponseLoading(false));
            missingPopup("API Key");
        }
        // else if (!credentials?.secretPhrase) missingPopup("Secret Phrase");
        else if (!credentials?.secretKey) {
            dispatch(setResponseLoading(false));
            missingPopup("Secret Key");
        } else {
            const response = await api("post", "binanceCredentials/create", {
                ...credentials,
                // isConnected: true,
            });
            dispatch(setResponseLoading(false));
            if (response?.status) {
                // toastify("Connected to exchange successfully.", "success");

                dispatch(updateUser(response?.data));
                dispatch(setShowConnectionSuccessModal(true))
                if (user?.firstVisit) {
                    setTimeout(async () => {
                        if (user?.role === "member") {
                            dispatch(setCurrentTab("subscribe"))
                            await api("post", "users/update", {
                                onboardingStep: "subscribe"
                            });
                        }
                        if (user?.role === "leader") {
                            dispatch(setCurrentTab("payment"))
                            await api("post", "users/update", {
                                onboardingStep: "payment"
                            });
                        }
                    }, 2000);
                }
                if (!callSubmit) {
                    switchToApiKeyView();
                } else {
                    setCallSubmit(false);
                }
            } else {
                setCallSubmit(false);

                // toastify(response?.message);
                dispatch(setConnectionFailMessage(response?.message))
                console.log("Connection fail error => ", response?.message)
                dispatch(setShowConnectionFailModal(true))
            }
        }
    };

    const disconnect = (exchange) => {
        setShowModal(true);
        setExchangeName(exchange);
    };

    const yesAction = async () => {
        setShowModal(false);
        dispatch(setLoading(true));

        const isConnected =
            exchangeName === "Binance"
                ? user?.binanceCredentials?.isConnected
                : exchangeName === "ByBit"
                    ? user?.bybitCredentials?.isConnected
                    : false;
        if (isConnected) {
            const credentialToUpdate =
                exchangeName === "Binance"
                    ? {
                        binanceCredentials: {
                            ...user?.binanceCredentials,
                            isConnected: false,
                            isConnectedSpot: false,
                            isConnectedUsdFuture: false,
                        },
                        onboarding: {
                            ...user.onboarding,
                            connect: false
                        }
                    }
                    : exchangeName === "ByBit"
                        ? {
                            bybitCredentials: {
                                ...user?.bybitCredentials,
                                isConnected: false,
                                isConnectedSpot: false,
                                isConnectedDerivatives: false,
                            },
                            onboarding: {
                                ...user.onboarding,
                                connect: false
                            }
                        }
                        : null;

            if (credentialToUpdate) {
                const response = await api(
                    "post",
                    "users/update",
                    credentialToUpdate
                );
                if (response?.status) {
                    dispatch(updateUser(response?.data?.user));
                    const response2 = await api("get", "users/index");
                    if (response2) {
                        dispatch(updateUser(response2?.data?.user));
                    }
                    dispatch(setLoading(true))
                    toastify("Exchange disconnected successfully.", "success");

                } else {
                    toastify(response?.message);
                }
            }
        }
        else {
            toastify("No credentials found to disconnect.", "error");
        }
        dispatch(setLoading(false));

    };

    const handleExchangeChange = (selectedValue) => {
        setSelectedConnectionType(selectedValue?.value)
        setCredentials((prev) => ({
            ...prev,
            exchange: selectedValue.value,
        }));
        getUserCredentials(selectedValue.value);
        const tempInput = document.createElement('input');
        document.body.appendChild(tempInput);
        tempInput.className = "hidden-input"
        tempInput.focus();

        setTimeout(() => {
            tempInput?.remove();
            if (inputRef.current) {
                inputRef?.current?.focus();
                // const end = inputRef.current.value.length;
                // inputRef.current.setSelectionRange(end, end);
            }
        }, 0);
    };

    const handleErrorModal = () => {
        dispatch(setShowConnectionFailModal(false))
        dispatch(setConnectionFailMessage(""))
    }

    // console.log(user, "MyBinaceUser")


    useEffect(() => {
        const handleBinanceConnection = async () => {
            const BinanceConnecting = localStorage.getItem("binanceCondition");

            if (BinanceConnecting) {
                try {
                    const myResponse = await api("get", "users/index");
                    if (myResponse) {
                        dispatch(updateUser(myResponse?.data?.user));
                    }

                    setTimeout(async () => {
                        const response2 = await api("get", "users/index");
                        if (response2) {
                            dispatch(updateUser(response2?.data?.user));
                        }
                    }, 300);

                    setTimeout(() => {
                        dispatch(updateUser({
                            ...user,
                            binanceCredentials: {
                                ...user.binanceCredentials,
                                isConnected: false
                            }
                        }));
                        localStorage.removeItem("binanceCondition");
                        dispatch(setLoading(false));
                    }, 100);
                    setTimeout(() => {
                        dispatch(updateUser({
                            ...user,
                            binanceCredentials: {
                                ...user.binanceCredentials,
                                isConnected: false
                            }
                        }));
                        localStorage.removeItem("binanceCondition");
                        dispatch(setLoading(false));
                    }, 400);
                    setTimeout(() => {
                        dispatch(updateUser({
                            ...user,
                            binanceCredentials: {
                                ...user.binanceCredentials,
                                isConnected: false
                            }
                        }));
                        localStorage.removeItem("binanceCondition");
                        dispatch(setLoading(false));
                    }, 500);

                } catch (error) {
                    console.error("Error fetching user data:", error);
                    dispatch(setLoading(false)); // Ensure loading is turned off even if there's an error
                }
            }
        };

        handleBinanceConnection();
    }, []);



    return (
        <React.Fragment>
            {showConnection && view === "connect" && (
                <div className="connection connectionJs pb-sm-50px">
                    {
                        loaders?.showConnectionSuccessModal ? (
                            <AuthLoader component="connectionsuccess" />
                        ) :
                            (loaders?.showConnectionFailModal) ?
                                (<AuthLoader component="connectionfail" handleErrorModal={handleErrorModal} connectionFailMessage={loaders?.connectionFailMessage} />)
                                :
                                loaders?.awaitingResponse ? (
                                    <AuthLoader component="connection" />
                                ) :
                                    (!localStorage.getItem("binanceCondition") &&
                                        <>
                                            <div className="title">

                                                {user?.binanceCredentials?.isConnected || user?.bybitCredentials?.isConnected ?
                                                    <>
                                                        <h3>Connected Trading Account</h3>
                                                        <p>
                                                            Your members are automatically copy trading everything you trade from this specific connected trading account.
                                                        </p>
                                                        <p className="sub-inner-heading">
                                                            Want to switch account?
                                                        </p>
                                                        <p>
                                                            Please hit ‘disconnect’ on your connected account and choose another. Please make sure to inform your subscribers that you’ve switched as they’ll need to switch too.
                                                        </p>
                                                    </>
                                                    :
                                                    <>
                                                        <h3>Connect  Your Trading Account</h3>
                                                        <p>
                                                            Your members will automatically be copy
                                                            trading everything you trade from the
                                                            account you’re connected with.
                                                        </p>
                                                    </>
                                                }
                                            </div>
                                            <div className="connectionBlock">
                                                <div
                                                    className={`accountCard ${user?.binanceCredentials?.isConnected
                                                        ? "active"
                                                        : ""
                                                        } ${user?.bybitCredentials?.isConnected && "d-none"}`}
                                                >
                                                    <div className="account">
                                                        <img src={Binance} alt="binance" />
                                                        <div className="d-flex align-items-center">
                                                            <p>API Status:</p>
                                                            <p>
                                                                <span
                                                                    className={
                                                                        user?.binanceCredentials
                                                                            ?.isConnectedUsdFuture
                                                                            ? "cyan"
                                                                            : "red"
                                                                    }
                                                                ></span>{" "}
                                                                Futures
                                                            </p>
                                                            <p>
                                                                <span
                                                                    className={
                                                                        user?.binanceCredentials
                                                                            ?.isConnectedSpot
                                                                            ? "cyan"
                                                                            : "red"
                                                                    }
                                                                ></span>{" "}
                                                                Spot
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            user?.binanceCredentials
                                                                ?.isConnected
                                                                ? disconnect("Binance")
                                                                : oauthBinance()
                                                        }
                                                    >
                                                        {user?.binanceCredentials?.isConnected
                                                            ? "Disconnect"
                                                            : "Connect"}
                                                    </button>
                                                </div>
                                                <div
                                                    className={`accountCard ${user?.bybitCredentials?.isConnected
                                                        ? "active"
                                                        : ""
                                                        } ${user?.binanceCredentials?.isConnected && "d-none"}`}
                                                >
                                                    <div className="account">
                                                        <img src={BayBit} alt="ByBit" />
                                                        <div className="d-flex align-items-center">
                                                            <p>API Status:</p>
                                                            <p>
                                                                <span
                                                                    className={
                                                                        user?.bybitCredentials
                                                                            ?.isConnectedDerivatives
                                                                            ? "cyan"
                                                                            : "red"
                                                                    }
                                                                ></span>{" "}
                                                                Futures
                                                            </p>
                                                            <p>
                                                                <span
                                                                    className={
                                                                        user?.bybitCredentials
                                                                            ?.isConnectedSpot
                                                                            ? "cyan"
                                                                            : "red"
                                                                    }
                                                                ></span>{" "}
                                                                Spot
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <button
                                                        onClick={() =>
                                                            user?.bybitCredentials?.isConnected
                                                                ? disconnect("ByBit")
                                                                : oauth()
                                                        }
                                                    >
                                                        {user?.bybitCredentials?.isConnected
                                                            ? "Disconnect"
                                                            : "Connect"}
                                                    </button>
                                                </div>
                                                {/* <div className="accountCard">
                                    <div className="account">
                                        <img
                                            className="disabled"
                                            src={Okx}
                                            alt="okx"
                                        />
                                        <div className="d-flex align-items-center">
                                            <p>API Status:</p>
                                            <p> Not connected</p>
                                        </div>
                                    </div>
                                    <button className="coming-soon">
                                        Coming Soon
                                    </button>
                                </div>
                                <div className="accountCard">
                                    <div className="account">
                                        <img
                                            className="disabled"
                                            src={Kucoin}
                                            alt="Kucoin"
                                        />
                                        <div className="d-flex align-items-center">
                                            <p>API Status:</p>
                                            <p> Not connected</p>
                                        </div>
                                    </div>
                                    <button className="coming-soon">
                                        Coming Soon
                                    </button>
                                </div> */}
                                                <div className="apiInsted">
                                                    {!user?.binanceCredentials?.isConnected && !user?.bybitCredentials?.isConnected &&
                                                        <h3 onClick={switchToApiKeyView}>
                                                            &gt; Use API keys instead
                                                        </h3>
                                                    }
                                                    {/* {user?.onboarding?.connect && user?.firstVisit &&
                                                        <button
                                                            className="btn cyan-btn fs13 ml-auto"
                                                            onClick={handleNavigate}
                                                        >
                                                            {user?.firstVisit
                                                                ? "Next"
                                                                : "Save Changes"}
                                                        </button>
                                                    } */}
                                                </div>
                                            </div>
                                        </>
                                    )}
                </div>
            )}
            {!showConnection && view === "apiKeys" && (
                <div className="connection connectionJs pb-sm-50px">
                    {loaders?.awaitingResponse ? (
                        <AuthLoader component="connection" />
                    ) :
                        loaders?.showConnectionSuccessModal ? (
                            <AuthLoader component="connectionsuccess" />
                        ) :
                            loaders?.showConnectionFailModal ?
                                (<AuthLoader component="connectionfail" handleErrorModal={handleErrorModal} connectionFailMessage={loaders?.connectionFailMessage} />)
                                :
                                (
                                    <>
                                        <div className="title">
                                            {/* <h3>Connect Your Trading Account</h3> */}
                                            {user?.binanceCredentials?.isConnected || user?.bybitCredentials?.isConnected ?
                                                <>
                                                    <p>
                                                        Your members are automatically copy trading everything you trade from this specific connected trading account.
                                                    </p>
                                                    <p className="sub-inner-heading">
                                                        Want to switch account?
                                                    </p>
                                                    <p>
                                                        Please hit ‘disconnect’ on your connected account and choose another. Please make sure to inform your subscribers that you’ve switched as they’ll need to switch too.
                                                    </p>
                                                </>
                                                :
                                                <>
                                                    <h3>Connect Your Trading Account</h3>
                                                    <p>
                                                        Your members will automatically be copy
                                                        trading everything you trade from the
                                                        account you’re connected with.
                                                    </p>
                                                </>
                                            }
                                        </div>
                                        <div className="connectionBlock">
                                            <div className="title mt-5 mb-3">
                                                <h3>Select your exchange(s) to connect</h3>
                                            </div>
                                            <ExchangeConnects
                                                onExchangeChange={handleExchangeChange}
                                                credentials={credentials}
                                            />
                                            {selectedConnectionType &&
                                                <>
                                                    <div className="messageInput">
                                                        <div className="form-floating">
                                                            <input
                                                                ref={inputRef}
                                                                type="text"
                                                                className="form-control"
                                                                id="apiKeys"
                                                                placeholder="API Key"
                                                                value={credentials?.apiKey}
                                                                onChange={(e) =>
                                                                    setCredentials((prev) => ({
                                                                        ...prev,
                                                                        apiKey: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                            <label for="apiKeys">Api Key</label>
                                                        </div>
                                                    </div>
                                                    <div className="messageInput">
                                                        <div className="form-floating">
                                                            <input
                                                                type="text"
                                                                className="form-control"
                                                                id="secretKey"
                                                                placeholder="Secret Key"
                                                                value={credentials?.secretKey}
                                                                onChange={(e) =>
                                                                    setCredentials((prev) => ({
                                                                        ...prev,
                                                                        secretKey: e.target.value,
                                                                    }))
                                                                }
                                                            />
                                                            <label for="secretKey">
                                                                Secret Key
                                                            </label>
                                                        </div>
                                                    </div>
                                                    {credentials?.exchange !== "ByBit" && (
                                                        <>
                                                            <p className="mt-4">
                                                                <strong>Important:</strong> your
                                                                exchange requires you to use our IP
                                                                address for the API setup.{" "}
                                                            </p>
                                                            <div className="url p-0">
                                                                <div className="position-relative d-flex align-items-center justify-content-end copyBlock mt-2">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control fs14 regular"
                                                                        value="108.129.27.187"
                                                                        disabled
                                                                    />
                                                                    <span className="position-absolute">
                                                                        <img
                                                                            className="cursor-pointer"
                                                                            onClick={() => {
                                                                                const communityIPAddress =
                                                                                    "108.129.27.187";

                                                                                navigator.clipboard
                                                                                    .writeText(
                                                                                        communityIPAddress
                                                                                    )
                                                                                    .then(() => {
                                                                                        toastify(
                                                                                            "IP Address copied to clipboard!",
                                                                                            "success"
                                                                                        );
                                                                                    })
                                                                                    .catch(
                                                                                        (error) => {
                                                                                            console.error(
                                                                                                "Clipboard write error:",
                                                                                                error
                                                                                            );
                                                                                            toastify(
                                                                                                "Failed to copy community link to clipboard.",
                                                                                                "error"
                                                                                            );
                                                                                        }
                                                                                    );
                                                                            }}
                                                                            src={copyIcon}
                                                                        />
                                                                    </span>
                                                                </div>
                                                                {/* <div className="d-flex align-items-center">
                            <p className="me-3">API Status:</p>
                            <div className="form-check">
                                <input
                                    className="form-check-input me-1"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault1"
                                />
                                <label
                                    className="form-check-label mt-1 me-2"
                                    for="flexRadioDefault1"
                                >
                                    Futures
                                </label>
                            </div>
                            <div className="form-check">
                                <input
                                    className="form-check-input me-1"
                                    type="radio"
                                    name="flexRadioDefault"
                                    id="flexRadioDefault2"
                                    checked
                                />
                                <label
                                    className="form-check-label mt-1"
                                    for="flexRadioDefault2"
                                >
                                    Spot
                                </label>
                            </div>
                        </div> */}
                                                            </div>
                                                        </>
                                                    )}
                                                </>
                                            }
                                            <div className="apiInsted">
                                                <h3 onClick={switchToApiKeyView}>
                                                    &gt; Use Quick connect
                                                </h3>
                                                {(credentials.apiKey && credentials.secretKey) &&
                                                    <button
                                                        className="btn cyan-btn fs13"
                                                        onClick={submit}
                                                    >
                                                        {user?.firstVisit
                                                            ? "Next"
                                                            : "Save Changes"}
                                                    </button>
                                                }
                                            </div>
                                        </div>
                                    </>
                                )}
                </div>
            )}
            {showModal && (
                <DeleteMember
                    noAction={noAction}
                    yesAction={yesAction}
                    text="Are you sure you want to Disconnect?"
                    subtext="If you do, your subscribers trades will still be active. Please advice them on how to proceed."
                />
            )}
            <RenderLinks page="copyTradeConnect" />
        </React.Fragment>
    );
}

export default Connection;

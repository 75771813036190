import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import useApi from "../../helpers/apiHelper/requestHelper";
import Button from "../../components/button/Button";
import { useEffect, useState } from "react";
import { updateUser } from "../../redux/users/usersSlice";
import { setLoading } from "../../redux/loading/loadingSlice";
import { toastify } from "../../helpers/toast/toastify";

export default function HowToGetPaid() {
    const user = useSelector((state) => state?.user?.user);
    const { id } = useParams();

    const [isCheckedUSD, setIsCheckedUSD] = useState(
        user?.getPaidInUSD || false
    );
    const [isCheckedCrypto, setIsCheckedCrypto] = useState(
        user?.getPaidInCrypto || false
    );
    const navigate = useNavigate();
    const api = useApi();

    const dispatch = useDispatch();

    const checkStripeAccount = async () => {
        dispatch(setLoading(true));
        try {
            const response = await api("get", "users/checkAccount");
            console.log("checkStripeAccount", response);

            if (response.status) {
                // Set the state to prevent showing the toast again
                if (response?.data?.url) {
                    toastify(response.message, "error");
                    window.location.assign(response?.data?.url);
                } else {
                    if (!user?.firstVisit) {
                        navigate("/leader/dashboard", { replace: true });
                    } else {
                        navigate("/leader/connectExchange");
                    }
                }
                dispatch(setLoading(false));
            }
            dispatch(setLoading(false));
        } catch (error) {
            console.error("Error:", error);
            toastify("An error occurred. Please try again.", "error");
        }
    };

    useEffect(() => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        if (id) {
            checkStripeAccount();
        }
    }, [user]);

    const handleUpdate = async () => {
        if (isCheckedUSD || isCheckedCrypto) {
            // Make an API request to update the user's profile based on the checkbox selections
            const updatedProfile = {
                getPaidInUSD: isCheckedUSD,
                getPaidInCrypto: isCheckedCrypto,
            };

            // Dispatch the action to update the user's profile in Redux and make the API request
            dispatch(setLoading(true));
            const response = await api("post", "users/update", updatedProfile);
            if (response.status) {
                dispatch(updateUser(response?.data?.user));
                if (response?.data?.user?.getPaidInUSD) {
                    window.location.assign(
                        user?.stripeCredentials?.stripeAccountLink
                    );
                    dispatch(setLoading(false));
                } else if (response?.data?.user?.getPaidInCrypto) {
                    dispatch(setLoading(false));
                    navigate("/leader/cryptoPayment");
                }
            }
        } else {
            toastify("Please select an option!"); // Set the flag to show the message
        }
    };

    const changeIsActive = (isChecked, type) => {
        if (type === "usd") {
            setIsCheckedUSD(isChecked);
            setIsCheckedCrypto(!isChecked); // Disable the other input
        } else if (type === "crypto") {
            setIsCheckedCrypto(isChecked);
            setIsCheckedUSD(!isChecked); // Disable the other input
        }
    };

    return (
        <>
            <div className="get_paid" style={{ padding: "0px 36px" }}>
                <section>
                    <div>
                        {user?.firstVisit && (
                            <h4 className="community_name mb-5 me-5">
                                {user?.leaderCommunity?.name}
                            </h4>
                        )}
                    </div>
                    <div className="mb-5">
                        <p className="main_heading">
                            OK, HOW DO YOU WANT TO GET PAID?
                        </p>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mb-5">
                        <div className="mb-3">
                            <div className="d-flex">
                                <div className="me-3">
                                    <input
                                        id="usdCheckbox"
                                        type="checkbox"
                                        checked={isCheckedUSD}
                                        onChange={(e) =>
                                            changeIsActive(
                                                e.target.checked,
                                                "usd"
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-1">
                                    <h4 className="trade_heading">
                                        I want to get paid in USD
                                    </h4>
                                    <p className="fs16">
                                        Subscriptions are handled by Stripe.com
                                        and if you want to get paid in USD they
                                        require:
                                    </p>
                                    <ul className="ms-3 mb-3 fs16">
                                        <li>
                                            Personal details (Phone, address
                                            etc.)
                                        </li>
                                        <li>
                                            Account number to transfer to (fx.
                                            Iban)
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="d-flex">
                                <div className="me-3">
                                    <input
                                        id="cryptoCheckbox"
                                        type="checkbox"
                                        checked={isCheckedCrypto}
                                        onChange={(e) =>
                                            changeIsActive(
                                                e.target.checked,
                                                "crypto"
                                            )
                                        }
                                    />
                                </div>
                                <div className="mt-1">
                                    <h4 className="trade_heading">
                                        I want to get paid in crypto
                                    </h4>
                                    <p className="fs16">
                                        Once a month we’ll pay you in USDT to
                                        your wallet. This requires:
                                    </p>
                                    <ul className="ms-3 mb-5 fs16">
                                        <li>
                                            USDT wallet address (on TRX
                                            network).
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="note">
                                <h4 className="note_msg bold">Please note!</h4>
                                <p className="note_msg">
                                    Your subscribers can only subscribe to your
                                    service using a card as of now (handled by
                                    Stripe.com). We’re working on launching
                                    crypto payments asap.
                                </p>
                            </div>
                        </div>
                    </div>
                    <Button
                        name="Next"
                        handleClick={() => {
                            handleUpdate();
                        }}
                    />
                </section>
            </div>
        </>
    );
}

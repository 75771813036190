import React, { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { setLoading } from "../../redux/loading/loadingSlice";
import useApi from "../../helpers/apiHelper/requestHelper";
import { useDispatch, useSelector } from "react-redux";

export default function VerifyPayment() {
    const api = useApi();
    const dispatch = useDispatch();
    let user = useSelector((state) => state?.user?.user);
    const { id } = useParams();
    const navigate = useNavigate();

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const NP_id = searchParams.get("NP_id");

    useEffect(() => {
        if (NP_id && id) {
            console.log({ NP_id, id });
            checkPayment();
        }
    }, []);

    console.log({ user });

    const checkPayment = async () => {
        dispatch(setLoading(true));
        let response = await api("post", "users/checkCryptoStatus", {
            paymentId: NP_id,
            userId: id,
        });
        dispatch(setLoading(false));
        console.log({ response });
        if (user) {
            navigate("/member/copyTrade", {
                state: { tab: "subscribe" }, replace: true
            });
        }
    };

    return <div>Verify Payment</div>;
}

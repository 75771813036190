import React, { useEffect, useState } from "react";

export default function Pagination({ page, setPage, items, filtered }) {
    const [pages, setPages] = useState([]);

    useEffect(() => {
        if (filtered?.length > 0) {
            setPages(
                Array.from(
                    { length: roundUpToLimit(filtered?.length, 10) / 10 || 1 },
                    (_, index) => index
                )
            );
        } else {
            setPages(
                Array.from(
                    { length: roundUpToLimit(items?.length, 10) / 10 || 1 },
                    (_, index) => index
                )
            );
        }
    }, [filtered, items]);

    function roundUpToLimit(number, limit) {
        const remainder = number % limit;
        return remainder === 0 ? number : number + (limit - remainder);
    }

    return (
        <div class="pagination">
            <div
                onClick={() => {
                    if (page !== 1) {
                        setPage(page - 1);
                    }
                }}
            >
                &laquo;
            </div>
            {pages?.map((e, i) => (
                <a
                    className={page === i + 1 && "activePage"}
                    onClick={() => setPage(i + 1)}
                >
                    {i + 1}{" "}
                </a>
            ))}
            <div
                onClick={() => {
                    if (page !== pages?.length) {
                        setPage(page + 1);
                    }
                }}
            >
                &raquo;
            </div>
        </div>
    );
}

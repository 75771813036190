import React, { useState, useRef, useEffect } from "react";
import edit_icon from "../../../assets/images/Pen.svg";
import { Link } from "react-router-dom";
import OrderSelect from "./OrderSelect";
import { useDispatch, useSelector } from "react-redux";
import Select, { components } from "react-select";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import useApi from "../../../helpers/apiHelper/requestHelper";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";
import RenderLinks from "../../../components/links/RenderLinks";
import { updateUser } from "../../../redux/users/usersSlice";
import { duration } from "moment";

const options = [
    {
        value: "monthly",
        label: "Monthly",
    },
    {
        value: "yearly",
        label: "Yearly",
    },
];

const monthlyPackages = [
    {
        label: `SMALL $69 / month (max. 1,000 users)`,
        value: {
            title: "$69 - SMALL",
            description: (
                <>
                    <p>Max. 1,000 users* / month</p>
                    <p>- iOS + Android</p>
                </>
            ),
        },
    },
    {
        label: "MEDIUM $89 / month (max. 5,000 users)",
        value: {
            title: "$89 - MEDIUM",
            description: (
                <>
                    <p>Max. 5,000 users* / month</p>
                    <p>- iOS + Android</p>
                    <p>- Push notifications</p>
                </>
            ),
        },
    },
    {
        label: "LARGE $149 / month (max. 50,000 users)",
        value: {
            title: "$149 - LARGE",
            description: (
                <>
                    <p>Max. 50,000 users* / month</p>
                    <p>Everything in ‘medium package’ plus:</p>
                    <p>- Publish app to Amazon + Huawei app stores</p>
                    <p>- App Review Reminders to users</p>
                </>
            ),
        },
    },
    {
        label: "XL $329 / month (unlimited users)",
        value: {
            title: "$329 - XL",
            description: (
                <>
                    <p>Unlimited users / month</p>
                    <p>Everything in ‘large package’ plus:</p>
                    <p>- Priority Support</p>
                </>
            ),
        },
    },
];

const yearlyPackages = [
    {
        label: "SMALL $690 / year (max. 1,000 users)",
        value: {
            title: "SMALL $690 / year (max. 1,000 users)",
            description: (
                <>
                    <p>Max. 1,000 users* / month</p>
                    <p>- iOS + Android</p>
                </>
            ),
        },
    },
    {
        label: "MEDIUM $890 / year (max. 5,000 users)",
        value: {
            title: "MEDIUM $890 / year (max. 5,000 users)",
            description: (
                <>
                    <p>Max. 5,000 users* / month</p>
                    <p>- iOS + Android</p>
                    <p>- Push notifications</p>
                </>
            ),
        },
    },
    {
        label: "LARGE $1490 / year (max. 50,000 users)",
        value: {
            title: "LARGE $1490 / year (max. 50,000 users)",
            description: (
                <>
                    <p>Max. 50,000 users* / month</p>
                    <p>Everything in ‘medium package’ plus:</p>
                    <p>- Publish app to Amazon + Huawei app stores</p>
                </>
            ),
        },
    },
    {
        label: "XL $3290 / year (unlimited users)",
        value: {
            title: "XL $3290 / year (unlimited users)",
            description: (
                <>
                    <p>Unlimited users / month</p>
                    <p>Everything in ‘large package’ plus:</p>
                    <p>- Priority Support</p>
                </>
            ),
        },
    },
];

function Order({ order }) {
    const user = useSelector((value) => value?.user?.user);
    const [data, setData] = useState({
        email: user?.email,
        appPackage: "",
    });
    const [howToPay, setHowToPay] = useState(null); // New state variable
    const emailRef = useRef(null);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const [emailDisabled, setEmailDisabled] = useState(true);
    const [show, setShow] = useState(null);
    const [packageData, setPackageData] = useState({})
    const [orderPlaced, setOrderPlaced] = useState({});
    const dispatch = useDispatch();
    const api = useApi();
    const inputRef = useRef(null);

    useEffect(() => {
        const monthlyIndex = Number(order?.type?.replace("monthly", "")) - 1;
        const yearlyIndex = Number(order?.type?.replace("yearly", "")) - 1;

        if (
            order?.type?.startsWith("monthly") &&
            monthlyIndex >= 0 &&
            monthlyIndex < monthlyPackages.length
        ) {
            setHowToPay(options[0]);
            setSelectedPackage(monthlyPackages[monthlyIndex]);
        } else if (
            order?.type?.startsWith("yearly") &&
            yearlyIndex >= 0 &&
            yearlyIndex < yearlyPackages.length
        ) {
            setHowToPay(options[1]);
            setSelectedPackage(yearlyPackages[yearlyIndex]);
        }
    }, [order]);

    const BoldFirstWordOption = (props) => {
        const firstWord = props.label.split(" ")[0];
        const restOfLabel = props.label.substring(firstWord.length);

        return (
            <components.Option {...props}>
                <span style={{ fontWeight: "bold", fontSize: "15px" }}>
                    {firstWord}
                </span>
                {restOfLabel}
            </components.Option>
        );
    };
    useEffect(() => {
        // Load the saved values from local storage
        const savedHowToPay = localStorage.getItem('howToPay');
        const savedSelectedPackage = localStorage.getItem('selectedPackage');
        if (savedHowToPay) {
            setHowToPay(JSON.parse(savedHowToPay));
        }
        if (savedSelectedPackage) {
            setSelectedPackage(JSON.parse(savedSelectedPackage));
        }
    }, []);
    const handleChange = (val) => {
        setOrderPlaced((prevOrder) => ({
            ...prevOrder,
            duration: val?.value // Use 'val' instead of 'selectedOption'
        }));
        setData({
            email: data.email,
            appPackage: "", // Reset the selected package when the payment option changes
        });
        setSelectedPackage(null); // Reset the selected package in state
        setHowToPay(val); // Set the payment option
        localStorage.setItem('howToPay', JSON.stringify(val));
    };

    const handlePackageChange = (val) => {
        setOrderPlaced((prevOrder) => ({
            ...prevOrder,
            title: {
                label: val?.label || "",
                value: {
                    title: val?.title || "",
                    description: val?.description || []
                }
            }
        }));
        setData((prevData) => ({
            ...prevData,
            appPackage: val?.title, // Update with the selected package's title
        }));
        setSelectedPackage(val); // Set the selected package in state
        localStorage.setItem('selectedPackage', JSON.stringify(val)); // Store the selected package in local storage
    };


    useEffect(() => {
        const fetchOrderStatus = async () => {
            try {
                const response = await api("get", "users/index");
                if (response) {
                    console.log("ResponseAppp", response?.data?.user?.appOrder);
                    setOrderPlaced(response?.data?.user?.appOrder);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchOrderStatus();
    }, []);
    console.log("hie")


    useEffect(() => {
        const fetchOrderStatus = async () => {
            try {
                const response = await api("get", "users/index");
                if (response) {
                    console.log("ResponseAppp", response?.data?.user?.appOrder);
                    setOrderPlaced(response?.data?.user?.appOrder);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetchOrderStatus();
    }, [show]);
    console.log(orderPlaced, "orderData")
    const handleOrderPlacement = async () => {
        if (orderPlaced?.order == true) return;
        const descriptionElements =
            selectedPackage?.value?.description.props.children;
        const paragraphs = descriptionElements.map(
            (element) => element.props.children
        );
        const orderData = {
            title: selectedPackage,
            description: paragraphs,
            user: data?.email,
            duration: howToPay?.value
        };
        console.log(descriptionElements, paragraphs, orderData, "handleorder===")
        try {
            dispatch(setLoading(true));
            const response = await api("post", "users/sendAppPackage", {
                orderData,
                appOrder: true,
            });
            console.log(response, "sendAppPackage")
            setPackageData(response)
            if (response?.status) {
                dispatch(updateUser(response?.data));
                toastify(response?.message, "success");
                setShow(null);
            } else {
                toastify(response?.message);
            }
            dispatch(setLoading(false));
        } catch (error) {
            console.error("Error sending order data:", error);
        }
    };

    const handleOrderClick = () => {

        if (!data?.email) {
            toastify("Please enter an email address.");
        } else if (orderPlaced?.order) {
            setShow(false)
        }
        else if (!howToPay) {
            toastify("Please select payment preference.");
        } else if (!selectedPackage) {
            toastify("Please select a package.");
        } else {
            if (orderPlaced?.order) {
                setShow(false); // Show second modal
            } else {

                setShow(true); // Show first modal
            }
        }
    };

    const handleEdit = () => {

        // Use setTimeout to ensure that the input is focused before selecting text
        let userAgent = navigator?.userAgent;
        let tempInput = null
        if (!userAgent?.includes("Firefox")) {
            // console.log("fireBox-Browser");
            tempInput = document.createElement('input');
            document.body.appendChild(tempInput);
            tempInput.className = "hidden-input"
            tempInput?.focus();
        }

        setTimeout(() => {
            if (tempInput) {
                tempInput?.remove();
            }
            if (inputRef?.current) {
                inputRef?.current?.focus();
                // const end = inputRef.current.value.length;
                // inputRef.current.setSelectionRange(end, end);
            }
        }, 0);
    };

    return (
        <React.Fragment>
            {show ? (
                <ConfirmationModal
                    text={`Thank you for your order. We’ve sent you an email with further information.`}
                    yesAction={() => handleOrderPlacement()}
                    noAction={() => setShow(null)}
                />
            ) : show == false ? (
                <ConfirmationModal
                    text={`You’ve already ordered app`}
                    yesAction={() => setShow(null)}
                    noAction={() => setShow(null)}
                    description={`Please contact support if you need to amend your order.`}
                />
            ) : ""}
            <div className="connection">
                <div className="mx-562">
                    {orderPlaced?.order ? (
                        <div className="title">
                            <h3>Yay! You’ve ordered the app!</h3>
                            <p>
                                Please check your email. You should have
                                received an email by now with instructions for
                                how to proceed.
                            </p>
                            <p>
                                If you haven’t received any email please contact
                                support and they’ll help you get started on
                                getting your APP asap!
                            </p>
                        </div>
                    ) : (
                        <div className="title">
                            <h3>Order your app</h3>
                            <p>
                                Once you’ve ordered your app you will receive a
                                confirmation email with a payment link to start
                                the process.
                            </p>
                        </div>
                    )}
                    <div className="customInput mt-5">
                        <label
                            onClick={() => {
                                setEmailDisabled(!emailDisabled);
                                // Use setTimeout to ensure that the input is enabled before focusing
                                setTimeout(() => {
                                    emailRef?.current?.focus();
                                }, 0);
                            }}
                            className="cursor-pointer editIcon"
                        >
                            <img
                                onClick={() => {
                                    handleEdit()
                                    setEmailDisabled(!emailDisabled)
                                }}
                                src={edit_icon}
                                alt="img"
                            />
                        </label>
                        <div className="form-floating">
                            <input
                                id="emailReff"
                                ref={emailRef}
                                type="text"
                                disabled={emailDisabled}
                                className="form-control"
                                placeholder="Your email address"
                                value={data?.email}
                                onChange={(e) =>
                                    setData({
                                        ...data,
                                        email: e.target.value,
                                    })

                                }
                            />
                            <label for="emailReff">Email</label>
                        </div>
                    </div>

                    <div className="">
                        <div className="settingDropdown">
                            <p className="descriptive">
                                How do you prefer to pay?
                            </p>
                            <div className="select mt-4">
                                <Select
                                    value={options.find(option => option.value === orderPlaced?.duration) || howToPay}
                                    options={options}
                                    onChange={handleChange}
                                    isSearchable={false}
                                    placeholder=""
                                    styles={{
                                        singleValue: (base) => ({
                                            ...base,
                                            display: "flex",
                                            alignItems: "center",
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            ...provided,
                                            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
                                            transition: 'transform 0.3s ease',
                                        }),

                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="select mt-4">
                        <Select
                            value={howToPay}
                            options={options}
                            onChange={handleChange}
                            isSearchable={false}
                            placeholder="How do you prefer to pay?"
                            styles={{
                                singleValue: (base) => ({
                                    ...base,
                                    display: "flex",
                                    alignItems: "center",
                                }),
                            }}
                        />
                    </div> */}


                    <div className="">
                        <div className="settingDropdown">
                            <p className="descriptive">
                                Select Package
                            </p>
                            <div className="select mt-4">
                                <Select
                                    value={
                                        orderPlaced?.title?.label && orderPlaced?.title?.value?.title
                                            ? {
                                                label: orderPlaced.title.label,
                                                value: orderPlaced.title.value.title,
                                            }
                                            : selectedPackage
                                    }
                                    options={
                                        howToPay?.value === "monthly"
                                            ? monthlyPackages
                                            : yearlyPackages
                                    }
                                    onChange={handlePackageChange}
                                    isSearchable={false}
                                    placeholder=""
                                    components={{ Option: BoldFirstWordOption }}
                                    styles={{
                                        singleValue: (base) => ({
                                            ...base,
                                            display: "flex",
                                            alignItems: "center",
                                        }),
                                        dropdownIndicator: (provided, state) => ({
                                            ...provided,
                                            transform: state.selectProps.menuIsOpen ? 'rotate(180deg)' : null,
                                            transition: 'transform 0.3s ease',
                                        }),
                                    }}
                                />
                            </div>
                        </div>
                    </div>

                    {/* <div className="select mt-4">
                        <Select
                            value={selectedPackage}
                            options={
                                howToPay?.value === "monthly"
                                    ? monthlyPackages
                                    : yearlyPackages
                            }
                            onChange={handlePackageChange}
                            isSearchable={false}
                            placeholder="Select Package"
                            components={{ Option: BoldFirstWordOption }}
                            styles={{
                                singleValue: (base) => ({
                                    ...base,
                                    display: "flex",
                                    alignItems: "center",
                                }),
                            }}
                        />
                    </div> */}
                    <div
                        className="d-flex justify-content-end mb-5"
                        style={{ marginTop: "30px" }}
                    >
                        <button
                            className="black-btn promoteBtn text_GM"
                            onClick={handleOrderClick}
                        >
                            Order
                        </button>
                    </div>
                </div>
                <div className="articleRelative">
                    <RenderLinks page="appOrder" />
                </div>
            </div>
        </React.Fragment>
    );
}

export default Order;

import { toast } from "react-toastify";

export const toastify = (message, status) => {
    if (status === "success") {
        toast.success(message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            zIndex: 10001,
            className: "toast-message",
        });
    } else {
        toast.error(message, {
            position: "top-center",
            autoClose: 4000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            zIndex: 10001,
            toastId: "error-toast",
            className: "toast-message",
        });
    }
};

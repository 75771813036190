import React, { useEffect, useRef, useState } from "react";
import Stripe from "../../../assets/images/stripe.png";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { useDispatch, useSelector } from "react-redux";
import { storeUser, updateUser } from "../../../redux/users/usersSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import DeleteMember from "../../../components/modals/DeleteMember";
import ConfirmationModal from "../../../components/modals/ConfirmationModal";

function Payment({ setTab }) {
    const [isEditing, setIsEditing] = useState(false);
    const [usdtWalletAddress, setUsdtWalletAddress] = useState("");
    const [checkedStripeAccount, setCheckedStripeAccount] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [showCryptoModal, setShowCryptoModal] = useState(false)
    const [show, setShow] = useState(null);
    const [showPaymentScreen, setShowPaymentScreen] = useState("")
    const dispatch = useDispatch();
    const location = useLocation();
    const api = useApi();
    const user = useSelector((state) => state?.user?.user);
    const { id } = useParams();
    const navigate = useNavigate();
    const inputRef = useRef(null);

    const noAction = () => {
        setShowModal(false);
    };

    const noCryptoAction = () => {
        setShowCryptoModal(false)
    }

    const handleSave = async () => {
        if (!usdtWalletAddress) {
            toastify("Please add wallet adddress!", "error");
            return;
        }
        dispatch(setLoading(true));
        const response = await api("post", "users/update", {
            usdtWalletAddress,
            getPaidInUSD: false,
            getPaidInCrypto: true,
        });
        if (response?.status) {
            dispatch(setLoading(false));

            dispatch(updateUser(response?.data?.user));
            setIsEditing(false);
            toastify("Wallet address added successfully!", "success");
            if (user?.firstVisit) {
                setTab("onboarding");
            }
        } else {
            dispatch(setLoading(false));
            toastify(response?.message);
        }
    };

    // const cryptoPopup = () => {
    //     setShowCryptoModal(true)
    // }

    const yesCryptoAction = () => {
        handleEdit()
        setShowCryptoModal(false)
    }



    const handleEdit = () => {
        setIsEditing(true);

        // Use setTimeout to ensure that the input is focused before selecting text
        const tempInput = document.createElement('input');
        document.body.appendChild(tempInput);
        tempInput.className = "hidden-input"
        tempInput.focus();

        setTimeout(() => {
            tempInput?.remove();
            if (inputRef.current) {
                inputRef?.current?.focus();
                // const end = inputRef.current.value.length;
                // inputRef.current.setSelectionRange(end, end);
            }
        }, 0);
    };

    const handleInputChange = (e) => {
        setUsdtWalletAddress(e.target.value);
    };

    const handleNavigate = () => {
        console.log(user?.onboarding);
        if (user?.onboarding?.payment) {
            setTab("onboarding");
        }
    };

    const removeAccountIdFromUrl = () => {
        // Assuming the URL structure is /leader/copyTrade/acct_1PgoHQRGFCNco7k0
        const newPath = location.pathname.replace(/\/acct_[^/]+$/, '');
        window.history.replaceState(null, '', newPath);
    };

    const handleUpdate = () => {
        // localStorage.setItem("showPaymentPage", true)
        setCheckedStripeAccount(true);

        setTimeout(() => {
            window.location.assign(user?.stripeCredentials?.stripeAccountLink);
        }, 0);
    };
    const stripeCheckAccount = async () => {
        dispatch(setLoading(true))
        let userAgent = navigator?.userAgent;
        if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
            console.log("Safari-Browser");
            dispatch(setLoading(false))
        }
        try {

            const response = await api("get", "users/checkAccount");
            dispatch(setLoading(false))

            if (response.message == "Connect Onboarding is not completed!") {
                dispatch(updateUser(response?.data?.user));
                setShowPaymentScreen("Connect Onboarding is not completed!")
                navigate("/leader/copyTrade");
                // dispatch(setLoading(false))

            } else if (response.message == "Details added" && !user?.firstVisit) {
                dispatch(updateUser(response?.data?.user));
                setShowPaymentScreen("Details added")
            }
            else if (response.message == "Can't create payments! You missed required information on stripe account.") {
                toastify("Can't create payments! You missed required information on stripe account.")
                setShowPaymentScreen("Can't create payments! You missed required information on stripe account.")
            }
            else if (user?.firstVisit) {
                // localStorage.removeItem("showPaymentPage")
                dispatch(updateUser(response?.data?.user));
                setTab("onboarding");
            }
        } catch {
            toastify("An error occurred. Please try again.", "error");
        }
    }

    useEffect(() => {
        stripeCheckAccount()
    }, []);

    const checkStripeAccount = async () => {
        dispatch(setLoading(true));
        let userAgent = navigator?.userAgent;
        if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
            console.log("Safari-Browser");
            dispatch(setLoading(false))
        }
        try {
            const response = await api("get", "users/checkAccount", { sendEmail: true });
            console.log("PaymentKey")
            // if (response?.message == "Can't create payments! You missed required information on stripe account.") {
            //     localStorage.removeItem("showPaymentPage")
            //     navigate("/leader/copyTrade");
            //     if (location.pathname.includes('/acct_')) {
            //         removeAccountIdFromUrl();
            //     }
            // }
            if (response.status) {
                dispatch(updateUser(response?.data?.user));
                console.log(response, "checkAccountresponse====")
                if (response?.data?.isValid) {
                    navigate("/leader/copyTrade");
                    console.log("insideDataValid");
                } else {
                    if (response?.data?.user) {
                        // localStorage.removeItem("showPaymentPage")
                        console.log("insideDataUser");
                        toastify(response?.message, "success");
                        const UpdateResponse = await api("post", "users/update", {
                            getPaidInUSD: true,
                            getPaidInCrypto: false,
                            usdtWalletAddress: ""
                        });
                        console.log(UpdateResponse, "UpdateRespoinseee")

                        if (location.pathname.includes('/acct_')) {
                            removeAccountIdFromUrl();
                        }
                        setIsEditing(false)
                        setUsdtWalletAddress("")
                        console.log(UpdateResponse, "UpdateResponse====")
                        if (UpdateResponse.status) {
                            dispatch(updateUser(UpdateResponse?.data?.user));

                        }
                    }
                    if (user?.firstVisit) {
                        // localStorage.removeItem("showPaymentPage")
                        setTab("onboarding");
                    }
                }
            } else {
                toastify(response?.message);
            }
        } catch (error) {
            console.error("Error:", error);
            toastify("An error occurred. Please try again.", "error");
        } finally {
            dispatch(setLoading(false));
        }
    };

    useEffect(() => {
        if (id && !checkedStripeAccount) {
            checkStripeAccount();
        }
    }, [id, checkedStripeAccount]);


    useEffect(() => {
        if (user?.usdtWalletAddress) {
            setUsdtWalletAddress(user.usdtWalletAddress || "");
        }
    }, [user]);


    const yesAction = async () => {
        // console.log(user, "myUserData")
        let userAgent = navigator?.userAgent;
        if (userAgent.includes("Safari") && !userAgent.includes("Chrome")) {
            console.log("Safari-Browser");
            dispatch(setLoading(false))
        } else {
            dispatch(setLoading(true))
        }

        setTimeout(() => {
            handleUpdate()
        }, 1000);
        setShowModal(false);
    }

    // console.log(user?.firstVisit, "firsTVisitcondition")

    // console.log(isEditing, "codolseediting")



    // console.log(usdtWalletAddress, "useWallletss")
    // console.log(!user?.getPaidInCrypto && !user?.getPaidInCrypto, "conditionCrypto&USD")

    return (<>

        <React.Fragment>
            {showPaymentScreen == "" ? "" :

                < div className="payment pb-sm-50px">
                    <div className="title">
                        <h3>How would you like to get paid?</h3>
                        <p>
                            All payments from your members are processed safely by Stripe.com (all major
                            creditcards are accepted) and soon we'll accept crypto payments too.
                        </p>
                        <p className="mt-3">
                            You can have the subscription fees paid to your account immediately via stripe.com
                            or have it paid in crypto once a month (USDT, on TRX network). All up to you.
                        </p>
                    </div>
                    <div className="mx-536">
                        <div
                            className={`paymentCard ${user?.getPaidInUSD ? "payment-complete py-comp-only-red-border" : ""}`}
                        >
                            <div className="account">
                                <h4>Get paid in USD</h4>
                                <img src={Stripe} alt="Stripe" />
                            </div>
                            <button
                                className={`black-btn promoteBtn ${user?.getPaidInUSD ? `cursorNotAllowed ${user?.firstVisit ? "" : "button-on-red"}` : ""}
                        }`}
                                // disabled={user?.getPaidInUSD}
                                // onClick={handleUpdate}
                                onClick={() => {
                                    if (!user?.getPaidInUSD && !user?.getPaidInCrypto) {
                                        handleUpdate();
                                    } else if (user?.getPaidInUSD) {
                                        setShow(true)
                                    } else {
                                        setShowModal(true);
                                    }
                                }}
                            >
                                {user?.getPaidInUSD ? "Cancel" : "Set up"}
                            </button>
                        </div>
                        <div
                            className={`paymentCard2 ${user?.getPaidInCrypto ? "py-comp-only-red-border" : ""
                                }`}
                        >
                            {/* <div className="account">
                            <h4>Get paid in crypto</h4>
                        </div> */}

                            <div className="saveEdit">
                                <div className="inputDiv">
                                    <div className="account">
                                        <h4>Get paid in crypto</h4>
                                    </div>
                                    <div className="form-floating">
                                        <input
                                            ref={inputRef}
                                            className="form-control"
                                            id="walletAddress"
                                            type="text"
                                            placeholder="Add USDT address (TRC20)"
                                            value={usdtWalletAddress}
                                            onChange={handleInputChange}
                                            disabled={!isEditing}
                                            style={{
                                                borderBottom: isEditing
                                                    ? "1px solid #000"
                                                    : "1px solid #000",
                                                backgroundColor: isEditing
                                                    ? "#fff"
                                                    : "transparent",
                                            }}
                                        />
                                        <label for="walletAddress fs13">
                                            Add USDT address (TRC20)
                                        </label>
                                    </div>
                                </div>
                                {isEditing ? (
                                    <button
                                        className={`black-btn promoteBtn`}
                                        onClick={handleSave}
                                    // onClick={cryptoPopup}
                                    >
                                        Save
                                    </button>
                                ) : (
                                    <button
                                        className={`black-btn promoteBtn  ${user?.getPaidInCrypto ? "cursorNotAllowed button-on-red" : ""}`}
                                        // onClick={handleEdit}
                                        // disabled={user?.getPaidInCrypto}
                                        onClick={() => {
                                            if (!user?.getPaidInUSD && !user?.getPaidInCrypto) {
                                                handleEdit();
                                            } else if (user?.getPaidInCrypto) {
                                                setShow(true)
                                            }
                                            else {
                                                setShowCryptoModal(true)
                                            }
                                        }}
                                    // onClick={cryptoPopup}
                                    >
                                        {user?.getPaidInCrypto ? "Cancel" : "Edit"}
                                    </button>
                                )}
                            </div>
                        </div>
                        {/* <div className="d-flex justify-content-end mt-5">
                        <button
                            className="btn cyan-btn fs13"
                            onClick={handleNavigate}
                        >
                            {user?.firstVisit ? "Next" : "Change option"}
                        </button>
                    </div> */}
                    </div>
                </div >
            }
            <RenderLinks page="copyTradePayment" />
        </React.Fragment >
        {showModal && (
            <DeleteMember
                noAction={noAction}
                yesAction={yesAction}
                text="Setting up USD payouts"
                subtext="This will delete your crypto payout setup and you will be paid in USD going forward."
            />
        )
        }
        {
            showCryptoModal && (
                <DeleteMember
                    noAction={noCryptoAction}
                    yesAction={yesCryptoAction}
                    text="Setting up crypto payouts"
                    subtext="This will delete your USD payout setup and you will be paid in crypto going forward."
                />
            )
        }
        {show &&
            <DeleteMember
                yesAction={() => setShow(false)}
                noAction={() => setShow(null)}
                text={`You’re about to cancel your current payout ${user?.getPaidInCrypto ? "crypto" : "setup"}?`}
                subtext={`If you do, please select other option to receive your payouts.`}
                noneclass="d-none"
            />
        }
    </>
    );
}

export default Payment;

import React from "react";
import { useSelector } from "react-redux";
import LeaderTradeSettings from "./LeaderTradeSettings";
import MemberTradeSettings from "./MemberTradeSettings";

export default function TradeSettings() {
    const userRole = useSelector((value) => value?.user?.user?.role);

    return userRole === "leader" ? (
        <LeaderTradeSettings />
    ) : (
        <MemberTradeSettings />
    );
}

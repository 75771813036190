import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import OnboardingModal from "../../../components/modals/OnboardingModal";
import { toastify } from "../../../helpers/toast/toastify";

function Introduction({ setTab }) {
    const [show, setShow] = useState(false);
    const user = useSelector((value) => value?.user?.user);

    useEffect(() => {
        if (user?.firstVisit) {
            setShow(true);
        }
    }, [user]);

    return (
        <>
            {!user?.firstVisit &&
                <React.Fragment>
                    <div className="subscription introJs">
                        {show && (
                            <OnboardingModal
                                noAction={() => {
                                    setShow(false);
                                }}
                                heading={
                                    <>
                                        Ok, please read this <br />
                                        introduction before <br /> completing the setup
                                    </>
                                }
                                handleClick={() => { toastify("Please complete your onboarding") }}
                            />
                        )}
                        <div className="title">
                            <h3>Important to read this before continuing</h3>
                            <p>
                                This information will help you navigate your copy trade
                                setup comfortably.
                            </p>
                        </div>
                        <div className="introduction">
                            <div className="block mt-41">
                                <h4 className="fs14">How it all works</h4>
                                <p>
                                    With our service you can kick back and relax as
                                    we’ll be doing all the trading for you. All you need
                                    to do is to complete the setup; connect, subscribe,
                                    pay and define your risk management. Each section
                                    will have a green checkmark when completed.
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="fs14">Is it safe?</h4>
                                <p>
                                    Yes. It’s as safe as the exchange security to
                                    connect your exchange. It happens through your
                                    exchange API. This means that we are only allowed to
                                    provide buy and sell signals to your trading
                                    account. Nothing else. We can’t withdraw any funds
                                    what so ever.
                                </p>
                            </div>
                            <div className="block">
                                <h4 className="fs14">
                                    Have funds on your trading account
                                </h4>
                                <p>
                                    Once you’ve completed the setup, please ensure
                                    you’ve got enough funds on your trading account. We
                                    recommend having the following on your trading
                                    account:{" "}
                                    <strong>
                                        $
                                        {
                                            user?.memberCommunities[0]?.community
                                                ?.onboardingUser?.minFund
                                        }
                                    </strong>
                                </p>
                            </div>
                            <div className="block">
                                <p>
                                    If you don’t have enough funds you won’t be trading
                                    as the exchanges require a minimum amount per trade.
                                    That being said, happy trading!
                                </p>
                            </div>
                            {/* <div className="d-flex justify-content-end">
                        <button
                            className="black-btn promoteBtn medium"
                            onClick={() => setTab("connection")}
                        >
                            Start
                        </button>
                    </div> */}
                        </div>
                    </div>
                </React.Fragment>
            }
        </>
    );
}

export default Introduction;

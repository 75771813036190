import {
    Route,
    createBrowserRouter,
    createRoutesFromElements,
    RouterProvider,
    Navigate,
    useLocation,
} from "react-router-dom";
import { LoginLayout } from "../layout/LoginLayout";
import ErrorPage from "../pages/errorPages/errorPage";
import Signin from "../pages/auth/signin";
import LandingPageMember from "../pages/landingPages/landingPageMember";
import LandingPageLeader from "../pages/landingPages/landingPageLeader.js";
import { UserLayout } from "../layout/UserLayout";
import SignupMember from "../pages/auth/signupMember";
import SignupLeader from "../pages/auth/signupLeader";
import ForgotPassword from "../pages/auth/forgotPassword";
import CreatePassword from "../pages/auth/createPassword";
import VerifyEmail from "../pages/auth/verifyEmail";
import OnboardingMember from "../pages/onboarding/onboardingMember";
import OnboardingLeader from "../pages/onboarding/onboardingLeader";
import LeaderDashboard from "../pages/leader/LeaderDashboard";
import CopyTrade from "../pages/sharedPages/copyTrade/Index.js";
import CopyTradeCm from "../pages/sharedPages/copyTradeCm/Index.js";
import Performance from "../pages/sharedPages/performance/Index.js";
import PerformanceCm from "../pages/sharedPages/performanceCm/Index.js";
import App from "../pages/sharedPages/app/Index.js";
import Members from "../pages/sharedPages/members/Index.js";
import MemberDashboard from "../pages/member/MemberDashboard";
import SubscriptionPlans from "../pages/sharedPages/subscriptionPlans/SubscriptionPlans";
import TradeSettings from "../pages/sharedPages/tradeSettings/TradeSettings";
import TradeSettingsCm from "../pages/sharedPages/tradeSettings/MemberTradeSettings.js";
import TradeHistory from "../pages/sharedPages/tradeHistory/TradeHistory";
import AccountSettings from "../pages/sharedPages/accountSettings/AccountSettings";
import CloseService from "../pages/sharedPages/accountSettings/CloseService.js";
import Support from "../pages/sharedPages/support/Support";
import LeaderExchange from "../pages/sharedPages/connect/LeaderExchange";
import MemberExchange from "../pages/sharedPages/connect/MemberExchange";
import ReferralCodes from "../pages/sharedPages/referralCodes/ReferralCodes";
import LeaderSubscribers from "../pages/sharedPages/support/LeaderSubscribers";
import CommunityDashboard from "../pages/sharedPages/community/CommunityDashboard";
import TradeActive from "../pages/sharedPages/tradeActive/TradeActive";
import HowToGetPaid from "../pages/leader/LeaderGetPaid";
import LeaderCryptoPayment from "../pages/leader/LeaderCryptoPayment";
import { useEffect } from "react";
import StorePastedURL from "../pages/auth/storeUrl";
import LeaderTrial from "../pages/leader/LeaderTrial";
import RiskManagement from "../pages/sharedPages/connect/RiskManagement";
import LeaderSocialPlatforms from "../pages/leader/LeaderSocialPlatforms.js";
import VerifyPayment from "../pages/sharedPages/VerifyPayment.js";
import EmailGetter from "../pages/auth/emailGetter.js";

export const MainRouting = () => {
    const router = createBrowserRouter(
        createRoutesFromElements(
            <>
                {/*login*/}
                <Route path="/login" element={<LoginLayout />}>
                    <Route index element={<Signin />}></Route>

                    {/* <Route path="leader/signup" element={<SignupLeader />}></Route> */}
                    <Route
                        path="forgotPassword"
                        element={<ForgotPassword />}
                    ></Route>
                    <Route
                        path="createPassword/:id"
                        element={<CreatePassword />}
                    ></Route>
                    <Route path="verifyEmail" element={<VerifyEmail />}></Route>
                </Route>

                <Route path="/signin" element={<LoginLayout />}>
                    <Route index element={<Signin />}></Route>

                    {/* <Route path="leader/signup" element={<SignupLeader />}></Route> */}
                    <Route
                        path="forgotPassword"
                        element={<ForgotPassword />}
                    ></Route>
                    <Route
                        path="createPassword"
                        element={<CreatePassword />}
                    ></Route>
                    <Route path="verifyEmail" element={<VerifyEmail />}></Route>
                </Route>

                <Route path="member/signup" element={<SignupMember />} />

                <Route path="/signup" element={<SignupLeader />} />

                <Route path="/verifyPayment/:id" element={<VerifyPayment />} />

                {/*Member*/}
                <Route
                    path="/member"
                    element={<UserLayout />}
                    errorElement={<ErrorPage />}
                >
                    <Route
                        path="dashboard"
                        element={<MemberDashboard />}
                    ></Route>
                    <Route path="copyTrade" element={<CopyTradeCm />}></Route>
                    <Route
                        path="copyTrade/:id"
                        element={<CopyTradeCm />}
                    ></Route>
                    <Route
                        path="performance"
                        element={<PerformanceCm />}
                    ></Route>
                    <Route
                        path="tradeSettings"
                        element={<TradeSettingsCm />}
                    ></Route>
                    <Route
                        path="subscriptionPlans"
                        element={<SubscriptionPlans />}
                    ></Route>
                    <Route
                        path="subscriptionPlans/:id"
                        element={<SubscriptionPlans />}
                    ></Route>
                    <Route
                        path="riskManagement/:exchange"
                        element={<RiskManagement />}
                    ></Route>
                    <Route
                        path="tradeHistory"
                        element={<TradeHistory />}
                    ></Route>
                    <Route
                        path="accountSettings"
                        element={<AccountSettings />}
                    ></Route>
                    {/* <Route
                        path="referralCodes"
                        element={<ReferralCodes />}
                    ></Route> */}
                    <Route path="support" element={<Support />}></Route>
                    {/* <Route
                        path="landingPage"
                        element={<LandingPageMember />}
                    ></Route> */}
                    <Route
                        path="connectExchange"
                        element={<MemberExchange />}
                    ></Route>
                    <Route
                        path="emailGetter"
                        element={<EmailGetter />}
                    ></Route>
                    <Route path="tradeActive" element={<TradeActive />}></Route>
                </Route>

                {/*Leader*/}
                <Route
                    path="/leader"
                    element={<UserLayout />}
                    errorElement={<ErrorPage />}
                >
                    <Route
                        path="dashboard"
                        element={<LeaderDashboard />}
                    ></Route>
                    <Route path="copyTrade" element={<CopyTrade />}></Route>
                    <Route path="copyTrade/:id" element={<CopyTrade />}></Route>
                    <Route path="performance" element={<Performance />}></Route>
                    <Route path="app" element={<App />}></Route>
                    <Route path="members" element={<Members />}></Route>
                    <Route
                        path="subscriptionPlans"
                        element={<SubscriptionPlans />}
                    ></Route>
                    <Route
                        path="social"
                        element={<LeaderSocialPlatforms />}
                    ></Route>
                    <Route path="trialPeriod" element={<LeaderTrial />}></Route>
                    <Route
                        path="getPaid/:id"
                        element={<HowToGetPaid />}
                    ></Route>
                    <Route path="getPaid" element={<HowToGetPaid />}></Route>
                    <Route
                        path="cryptoPayment"
                        element={<LeaderCryptoPayment />}
                    ></Route>
                    <Route
                        path="tradeSettings"
                        element={<TradeSettings />}
                    ></Route>
                    <Route
                        path="tradeHistory"
                        element={<TradeHistory />}
                    ></Route>
                    <Route
                        path="accountSettings"
                        element={<AccountSettings />}
                    ></Route>
                    <Route
                        path="closeService"
                        element={<CloseService />}
                    ></Route>
                    <Route
                        path="referralCodes"
                        element={<ReferralCodes />}
                    ></Route>
                    <Route path="support" element={<Support />}></Route>
                    <Route
                        path="subscribers"
                        element={<LeaderSubscribers />}
                    ></Route>
                    {/* <Route
                        path="landingPage"
                        element={<LandingPageLeader />}
                    ></Route> */}
                    <Route
                        path="connectExchange"
                        element={<LeaderExchange />}
                    ></Route>
                    <Route
                        path="emailGetter"
                        element={<EmailGetter />}
                    ></Route>
                    <Route path="tradeActive" element={<TradeActive />}></Route>
                </Route>

                <Route
                    path="/admin"
                    element={<UserLayout />}
                    errorElement={<ErrorPage />}
                >
                    <Route
                        path="dashboard"
                        element={<LeaderDashboard />}
                    ></Route>
                </Route>

                {/*Leader Onboarding*/}
                {/* <Route
                    path="/leader"
                    element={<LoginLayout />}
                    errorElement={<ErrorPage />}
                >
                    <Route index element={<OnboardingLeader />}></Route>
                </Route> */}

                {/*Member Onboarding*/}
                {/* <Route
                    path="/member"
                    element={<LoginLayout />}
                    errorElement={<ErrorPage />}
                >
                    <Route index element={<OnboardingMember />}></Route>
                </Route> */}

                <Route
                    path="/community/:communityName"
                    element={<CommunityDashboard />}
                />

                <Route path="*" element={<Navigate to="/login" />} />
            </>
        )
    );
    return <RouterProvider router={router} />;
};

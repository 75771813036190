import React from "react";
import close_success from "../../../assets/images/close_success.svg";
import copy_icon from "../../../assets/images/Copy.svg";
import { useDispatch, useSelector } from "react-redux";
import { toastify } from "../../../helpers/toast/toastify";
import { Form } from "react-bootstrap";
import { setLoading } from "../../../redux/loading/loadingSlice";
// import { api } from "../../../helpers/apiHelper/requestHelper";
import { updateUser } from "../../../redux/users/usersSlice";
import useApi from "../../../helpers/apiHelper/requestHelper";

export default function MemberReferralCodes() {
    const user = useSelector((e) => e?.user?.user);
    const dispatch = useDispatch();
    const api = useApi();

    const setReferralCode = async (e) => {
        let value = e.target.checked;
        dispatch(setLoading(true));
        let response = await api("post", "users/update", {
            ...user,
            referralCode: { ...user.referralCode, isActive: value },
        });
        if (response?.status) {
            dispatch(updateUser(response?.data?.user));
        }
        dispatch(setLoading(false));
    };

    return (
        <div className="row justify-content-center h-100">
            <form className="col-lg-10 d-flex flex-column">
                <div className="page_title">
                    <h4 className="fs25 text_GB">Referral codes</h4>
                </div>
                <div className="h-100 d-flex flex-column">
                    <div className="page_form d-grid gap-4 mt-sm-5 mt-3 pt-sm-4 pt-3">
                        <div className="d-grid gap-5">
                            <div className="d-grid gap-4">
                                <h4 className="fs16">
                                    <div className="text_GB">
                                        25% Discount for first month membership
                                        for friend that signs up.
                                    </div>
                                </h4>
                                <div className=" d-flex align-items-center flex-row">
                                    <div className="col-sm-6 mt-1 position-relative d-flex align-items-center justify-content-end">
                                        <input
                                            type="text"
                                            className="form-control fs15"
                                            id="copyValue"
                                            value={user?.referralCode?.code}
                                        />
                                        <span className="position-absolute me-3">
                                            <img
                                                src={copy_icon}
                                                alt="img"
                                                className="cursor-pointer"
                                                onClick={() => {
                                                    navigator.clipboard.writeText(
                                                        `${user?.referralCode?.code}`
                                                    );
                                                    toastify(
                                                        "Discount code copied to clipboard!",
                                                        "success"
                                                    );
                                                }}
                                            />
                                        </span>
                                    </div>

                                    <div className="ms-3 d-flex align-items-center">
                                        <input
                                            id="1"
                                            type="checkbox"
                                            checked={
                                                user?.referralCode?.isActive
                                            }
                                            onChange={setReferralCode}
                                        />
                                        <label for="1" className="ms-2">
                                            Activate Code
                                        </label>
                                    </div>
                                    {/* <Form.Check
                                        className="form-check-community ms-4"
                                        type={"checkbox"}
                                        label="Activate Code"
                                        checked={user?.referralCode?.isActive}
                                        onChange={setReferralCode}
                                    /> */}
                                </div>
                            </div>
                        </div>
                        {/* <p>
                            Read more about our referral program{" "}
                            <a href="#" className="text-dark">
                                here
                            </a>
                        </p> */}
                    </div>
                </div>
            </form>
        </div>
    );
}

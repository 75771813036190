import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    community: null,
};

export const communitySlice = createSlice({
    name: "communitySlice",
    initialState,
    reducers: {
        userCommunity: (state, action) => {
            state.community = action.payload;
        },
    },
});

export const { userCommunity } = communitySlice.actions;

export default communitySlice.reducer;

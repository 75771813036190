import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux/es";

export default function RenderLinks({ page }) {
    const reduxData = useSelector((state) => state);

    const getUrl = (url) => {
        if (url.startsWith("http")) {
            return url;
        } else {
            return `https://${url}`;
        }
    };

    const getPageData = (role) => {
        let found = reduxData?.links?.allLinks?.find(
            (e) => e.page === page && e.role === role
        );
        if (found) {
            return (
                <div className="article">
                    {/* <h4>Help articles</h4>
                    {found?.links?.map((link, e) => (
                        <a
                            key={e}
                            target="_blank"
                            rel="noopener noreferrer"
                            href={getUrl(link?.url)}
                        >
                            &gt; {link?.text}
                        </a>
                    ))} */}
                </div>
            );
        }
    };

    return getPageData(reduxData?.user?.user?.role);
}

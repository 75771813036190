import React, { useEffect } from "react";
import settingsIcon from "../../assets/images/Setting.gif";
import hourGlassIcon from "../../assets/images/Hourglass.gif";
import loaderGif from "../../assets/images/loading.gif";
import reportGif from "../../assets/images/report.gif";
import successGif from "../../assets/images/success.gif";
import errorGif from "../../assets/images/error.gif";

export default function AuthLoader({ component, handleErrorModal, connectionFailMessage, user, closeSubHeadline, closeHeadline }) {

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: 'instant' })
    }, []);

    if (component === "settings") {
        return (
            <div className="authLoader">
                <div className="text-center">
                    <h3 className="fs20 bold li_h30">Alright, let's get you started</h3>
                    <p className="fs14 mb-50 mt-2">{user?.role === "member" ? 'Now you just need to connect and subscribe' : 'Please hold'}</p>
                    <img src={settingsIcon} alt="settingsIcon" height={120} />
                </div>
            </div>
        );
    } else if (component === "connection") {
        return (
            <div className={`connectLoader ${(component === "connectionfail" || component === "connectionsuccess") && "d-none"}`}>
                <div className="text-center">
                    <h3 className="fs20 bold li_h30 margin-3">{closeHeadline ? closeHeadline : "Connecting"} </h3>
                    <p className="fs14 mb-50">{closeSubHeadline ? closeSubHeadline : "Please hold"}</p>
                    <img src={loaderGif} className="connecting-loader" alt="loaderGif" />
                </div>
            </div>
        );
    } else if (component === "report") {
        return (
            <div className="connectLoader">
                <div className="text-center">
                    <h3 className="fs20 li_h30 bold mb-50">
                        Fetching all your stats now
                    </h3>
                    <img src={reportGif} alt="loaderGif" height={60} />
                </div>
            </div>
        );
    }
    else if (component === "connectionsuccess") {
        return (
            <div className="connectLoader">
                <div className="text-center">
                    <h3 className="fs20 bold li_h30">{closeHeadline ? closeHeadline : " You're now connected"}</h3>
                    <p className="fs14 mb-50">{closeSubHeadline ? closeSubHeadline : "Please wait"}</p>
                    <img src={successGif} className="success-loader" alt="loaderGif" />
                </div>
            </div>
        );
    }
    else if (component === "connectionfail") {
        return (
            <div className="connectLoader">
                <div className="text-center">
                    <h3 className="fs20 li_h30 bold">
                        Oops. Something went wrong : &#40;
                    </h3>
                    <p className="fs14 mb-26 mt-2">{connectionFailMessage || "Error"}</p>
                    <img src={errorGif} alt="loaderGif" className="error-loader" /> <br />
                    {/* <p className="my-3 font-bold">{connectionFailMessage || "Error"}</p> */}
                    <button style={{ width: "294px" }} className="newCreateButton" onClick={() => { handleErrorModal() }}>Try again</button>
                </div>
            </div>
        );
    }
    else {
        return (
            <div className="authLoader">
                <div className="text-center">
                    <h3 className="fs20 bold li_h30 my-3">Signing you in</h3>
                    <img src={hourGlassIcon} alt="settingsIcon" height={60} />
                </div>
            </div>
        );
    }
}

import { useNavigate } from "react-router-dom";
import logo from "../assets/images/logo_black.png";
import React from "react";
import { useSelector } from "react-redux";

export default function Header() {
    const navigate = useNavigate();

    return (
        // <header>
        //     <div className="container" style={{ zIndex: -1 }}>
        //         <div className="row">
        //             <div className="col-xl-12 d-flex align-items-center justify-content-center pt-4 pb-4">
        //                 <a className="site_name">
        //                     {/* <img
        //                         src={logo}
        //                         alt="img"
        //                         className="logo"
        //                         // onClick={() => navigate("dashboard")}
        //                     /> */}
        //                 </a>
        //             </div>
        //         </div>
        //     </div>
        // </header>
        <></>
    );
}

import React from "react";
import copy_icon from "../../../assets/images/Copy.svg";
import close_success from "../../../assets/images/close_success.svg";

export default function LeaderTradeSettings() {
  return (
    <div className="row justify-content-center h-100">
      <form className="col-lg-10 d-flex flex-column">
        <div className="page_title d-sm-flex align-items-sm-center justify-content-sm-between">
          <h4 className="fs25 text_GB">Trade settings - settings</h4>
        </div>
        <div className="h-100 d-flex flex-column">
          <div className="page_form d-grid gap-4 mt-sm-5 mt-3 pt-sm-4 pt-3 pb-sm-5">
            <h4 className="fs16 text_GB">
              Create your TradingView alert message
            </h4>
            <div className="row">
              <div className="col-lg-8 pe-lg-4">
                <label className="fs15"> Trading pair </label>
                <input
                  type="text"
                  className="form-control fs15 mt-1"
                  placeholder="Input: coin / usdt"
                />
              </div>
              <div className="col-lg-4 ps-lg-4 col-6 mt-lg-0 mt-4 pt-lg-0 pt-1">
                <label className="fs15"> Set stop loss </label>
                <input
                  type="text"
                  className="form-control fs15 mt-1"
                  placeholder="SL %"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-8 pe-lg-4">
                <label className="fs15 d-flex align-items-center" role="button">
                  <input type="checkbox" className="me-2" />
                  Move SL to entry when price hits
                </label>
                <div className="d-flex align-items-center mt-3">
                  <div className="custom-select w-100">
                    <select>
                      <option value="0">Select TP</option>
                      <option value="1">TP 1</option>
                      <option value="2">TP 2</option>
                      <option value="3">TP 3</option>
                    </select>
                  </div>
                  <p className="fs15 px-2">OR</p>
                  <input
                    type="text"
                    className="form-control fs15"
                    placeholder="X % from entry"
                  />
                </div>
                <p className="fs12 mt-2 pt-sm-0 pt-1 text-muted text-center text-sm-start">
                  Only one can be selected.
                </p>
                <div className="row mt-3 pt-3">
                  <div className="col-6">
                    <label className="fs15"> Set take profit(s) </label>
                    <input
                      type="text"
                      className="form-control fs15 mt-1"
                      placeholder="TP1 %"
                    />
                    <input
                      type="text"
                      className="form-control fs15 mt-3"
                      placeholder="TP2 %"
                    />
                    <input
                      type="text"
                      className="form-control fs15 mt-3"
                      placeholder="TP3 %"
                    />
                  </div>
                  <div className="col-6">
                    <label className="fs15"> % of position </label>
                    <input
                      type="text"
                      className="form-control fs15 mt-1"
                      placeholder="% of position"
                    />
                    <input
                      type="text"
                      className="form-control fs15 mt-3"
                      placeholder="% of position"
                    />
                    <input
                      type="text"
                      className="form-control fs15 mt-3"
                      placeholder="% of position"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-4 ps-lg-4 mt-lg-0 mt-4 pt-lg-0 pt-3">
                <label className="fs15"> Portfolio size per trade </label>
                <div className="row">
                  <div className="col-lg-12 col-6">
                    <input
                      type="text"
                      className="form-control fs15 mt-lg-3 mt-1"
                      placeholder="Size in %"
                    />
                  </div>
                </div>
              </div>
              <div className="col-lg-12 pt-4 mt-3">
                <p className="fs15 text-700">Your TradingView alert message</p>
                <div className="position-relative d-flex align-items-end justify-content-end mt-1">
                  <textarea
                    rows="5"
                    id="copyValues"
                    placeholder="Generated code that fits"
                    className="form-control fs15 text-700 resize_null"
                  ></textarea>
                  <span className="position-absolute mb-3 me-3">
                    <img
                      src={copy_icon}
                      alt="img"
                      onclick="copyClipboards()"
                      data-bs-toggle="modal"
                      data-bs-target="#copyClipboards"
                      role="button"
                    />
                  </span>

                  <div
                    className="modal fade"
                    id="copyClipboards"
                    tabindex="-1"
                    aria-labelledby="copyClipboardsLabel"
                    aria-hidden="true"
                  >
                    <div className="modal-dialog modal-sm modal-dialog-centered">
                      <div className="modal-content">
                        <div className="modal-body position-relative">
                          <img
                            src={close_success}
                            alt="img"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            className="position-absolute"
                            role="button"
                          />

                          <p className="fs16 text-700">Alert message copied!</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 pt-4 position-relative">
                <label className="fs15"> Webhook </label>
                <div className="d-flex align-items-center justify-content-end">
                  <input
                    type="text"
                    className="form-control fs15 mt-1"
                    id="copyValue"
                    value="3fdgacoodekJJ"
                  />
                  <span className="position-absolute me-3">
                    <img
                      src={copy_icon}
                      alt="img"
                      onclick="copyClipboard()"
                      data-bs-toggle="modal"
                      data-bs-target="#copyClipboard"
                      role="button"
                    />
                  </span>
                </div>
                <div
                  className="modal fade"
                  id="copyClipboard"
                  tabindex="-1"
                  aria-labelledby="copyClipboardLabel"
                  aria-hidden="true"
                >
                  <div className="modal-dialog modal-sm modal-dialog-centered">
                    <div className="modal-content">
                      <div className="modal-body position-relative">
                        <img
                          src={close_success}
                          alt="img"
                          data-bs-dismiss="modal"
                          aria-label="Close"
                          className="position-absolute"
                          role="button"
                        />

                        <p className="fs16 text-700">Weebhook copied!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h4 className="fs15 mt-sm-0 mt-5">
              Any problems with setting up connecting to your exchange please
              click{" "}
              <a href="#" className="text-dark">
                <u className="text_GB">here </u>
              </a>
            </h4>
          </div>
          <div className="mt-sm-auto mt-5">
            <a href="#" className="btn btn-success radius min_h57 w_261 fs20">
              SAVE
            </a>
          </div>
        </div>
      </form>
    </div>
  );
}

import React from "react";
import Close from "../../assets/images/cancelBlack.svg";

export default function CloseCopyTrade({ noAction, yesAction }) {
    return (
        <div className="modal fade show zoomed">
            <div
                className="modal-dialog modal-sm modal-dialog-centered"
                style={{ maxWidth: "411px" }}
            >
                <div className="modal-content" style={{ marginTop: "20vh" }}>
                    <div className="modal-body p-0 position-relative text-center closeService">
                        <div
                            className="d-flex justify-content-end mx-3 mt-3"
                            onClick={noAction}
                        >
                            <img
                                src={Close}
                                alt="close"
                                className="cursor-pointer cross"
                            />
                        </div>
                        <div className="mx-lg-5 mt-23 mx-3">
                            <h3 className="fs20 bold red-text">
                                Are you sure you want to <br /> close your
                                service?
                            </h3>
                            <p className="fs16 mt-19 red-text">
                                This action can’t be undone. <br />
                                All subscriptions and all trades will be
                                cancelled immediately. No refunds.
                            </p>
                        </div>
                        <div className="d-flex justify-content-center mb-40 mt-33 redBtn">
                            <button
                                className="red-btn fs13 medium closeBtn"
                                onClick={yesAction}
                            >
                                Yes, close my service
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

import React from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from "./reportWebVitals";
import { MainRouting } from "./routes/MainRouting";
import { CustomToastContainer } from "./helpers/toast/toastContainer";
import { PersistGate } from "redux-persist/integration/react";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "./style.css";
import "../src/assets/css/responsive.css";
import "react-datepicker/dist/react-datepicker.css";
import TourProvider from "./components/tour-provider/TourProvider";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    // <React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <CustomToastContainer />
            {/* <TourProvider> */}
            <MainRouting />
            {/* </TourProvider> */}
        </PersistGate>
    </Provider>
    // </React.StrictMode>
);

reportWebVitals();

import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    isLoading: false,
    awaitingResponse: false,
    settingUpService: false,
    signingYouIn: false,
    reportLoading: false,
    showConnectionSuccessModal: false,
    showConnectionFailModal: false,
    connectionFailMessage: "",
    binanceConnecting: false
};

export const loadingSlice = createSlice({
    name: "loadingSlice",
    initialState,
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
        setResponseLoading: (state, action) => {
            state.awaitingResponse = action.payload;
        },
        setSettingUpService: (state, action) => {
            state.settingUpService = action.payload;
        },
        setSigningYouIn: (state, action) => {
            state.signingYouIn = action.payload;
        },
        setReportLoading: (state, action) => {
            state.reportLoading = action.payload;
        },
        setShowConnectionSuccessModal: (state, action) => {
            state.showConnectionSuccessModal = action.payload;
        },
        setShowConnectionFailModal: (state, action) => {
            state.showConnectionFailModal = action.payload;
        },
        setConnectionFailMessage: (state, action) => {
            state.connectionFailMessage = action.payload;
        },
        setBinanceConnecting: (state, action) => {
            state.binanceConnecting = action.payload;
        }
    },
});

export const {
    setLoading,
    setResponseLoading,
    setSettingUpService,
    setSigningYouIn,
    setReportLoading,
    setShowConnectionSuccessModal,
    setShowConnectionFailModal,
    setConnectionFailMessage,
    setBinanceConnecting
} = loadingSlice.actions;

export default loadingSlice.reducer;

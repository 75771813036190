import React from "react";
import { useSelector } from "react-redux";
import LeaderSubscriptions from "./LeaderSubscriptions";
import MemberSubscriptions from "./MemberSubscriptions";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

export default function SubscriptionPlans() {
    const userRole = useSelector((value) => value?.user?.user?.role);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

    return (
        <Elements stripe={stripePromise}>
            {userRole === "leader" ? (
                <LeaderSubscriptions />
            ) : (
                <MemberSubscriptions />
            )}
        </Elements>
    );
}
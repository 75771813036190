import React, { useEffect, useState } from "react";
import searchIcon from "../../../assets/images/search-icon.svg";
import { useDetectClickOutside } from "react-detect-click-outside";
import Pagination from "../../../components/Pagination";
import Cross from "../../../assets/images/cancel.svg";
import Message from "../../../assets/images/Envelope.svg";
import DeleteMember from "../../../components/modals/DeleteMember";
import { useDispatch, useSelector } from "react-redux";
import useApi from "../../../helpers/apiHelper/requestHelper";
import { updateUser } from "../../../redux/users/usersSlice";
import { setLoading } from "../../../redux/loading/loadingSlice";
import { toastify } from "../../../helpers/toast/toastify";
import RenderLinks from "../../../components/links/RenderLinks";
import NoDataGif from "../../../assets/images/no_data.gif"
import { simplifyTradingVolume } from "../../../helpers/helper";

export default function MembersOverview({ setTab, setUser }) {
    const [show, setShow] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [activeTab, setActiveTab] = useState("name");
    const [selectedSubscription, setSelectedSubscription] = useState("");
    const [selectedUser, setSelectedUser] = useState("");
    const [filteredParticipants, setFilteredParticipants] = useState([]);
    const [filterData, setFilterData] = useState(null)
    const [page, setPage] = useState(1);
    const api = useApi();
    const dispatch = useDispatch();
    const [searchTerm, setSearchTerm] = useState("");
    const ref = useDetectClickOutside({
        onTriggered: () => {
            setShow(false)
            setSearchTerm("")
        }
    });
    const participants = useSelector(
        (state) => state?.user?.user?.leaderCommunity?.participants
    );
    const leaderCommunity = useSelector(
        (state) => state?.user?.user?.leaderCommunity
    );

    useEffect(() => {
        const fetchBalances = async () => {
            const response = await api("get", "users/getMembersBalance");
            if (response?.status) {
                dispatch(updateUser(response?.data));
            }
            setFilterData(response?.data?.leaderCommunity?.participants)

            console.log(response, "memberresponse")
        };

        console.log(filterData, "filterdata")

        fetchBalances();
    }, []);
    console.log(filterData, "length")

    useEffect(() => {
        if (!Array.isArray(participants)) {
            return;
        }
        let filtered = [...participants];

        filtered = filtered.filter((e) =>
            e?.user?.email?.toLowerCase()?.includes(searchTerm.toLowerCase())
        );
        setFilteredParticipants(filtered);
    }, [searchTerm, participants]);

    const noAction = () => {
        setShowModal(false);
    };

    const handleMessage = (val) => {
        setTab("message");
        setUser(val);
    };

    const yesAction = async () => {
        setShowModal(false);
        dispatch(setLoading(true));
        const response = await api("post", "users/unsubscribe", {
            sessionId: selectedSubscription,
            communityId: leaderCommunity._id,
            userId: selectedUser,
            leader: true,
        });

        if (response?.status) {
            toastify("User unsubscribed successfully.", "success");
            dispatch(updateUser(response?.data?.user));
        } else {
            toastify(response?.message);
        }

        dispatch(setLoading(false));

        setSelectedSubscription("");
        setSelectedUser("");
    };

    return (
        <div className="row justify-content-center h-100">
            <div className="col-lg-12 d-flex flex-column">
                <div className="connection">
                    <div className="mx-556">
                        {participants?.length > 0 &&
                            <div className="title mb-3">
                                <h3>Members</h3>
                            </div>
                        }
                        <div className="page_title d-flex align-items-center justify-content-end flex-nowrap mb-3">
                            <div className="custom-dropdown d-flex" ref={ref}>
                                {participants?.length > 0 &&
                                    <button
                                        className="dropdown-toggle position-unset"
                                        onClick={() => setShow(!show)}
                                    >
                                        <img
                                            className="filter-icon"
                                            alt="searchIcons"
                                            src={searchIcon}
                                        />
                                    </button>
                                }
                                {show && (
                                    <div className="dropdown-menu">
                                        <div className="membersSearch">
                                            <div className="tabs">
                                                <button
                                                    className={`tab ${activeTab === "name"
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    onClick={() =>
                                                        setActiveTab("name")
                                                    }
                                                >
                                                    Email
                                                </button>
                                                {/* <button
                                                    className={`tab ${activeTab === "tier"
                                                        ? "active"
                                                        : ""
                                                        }`}
                                                    onClick={() =>
                                                        setActiveTab("tier")
                                                    }
                                                >
                                                    Tier
                                                </button> */}
                                            </div>
                                            {activeTab === "name" && (
                                                <div>
                                                    {participants?.length > 0 ? (
                                                        <div className="tab-contents">
                                                            <input
                                                                type="text"
                                                                placeholder="Search email"
                                                                value={
                                                                    searchTerm
                                                                }
                                                                onChange={(e) =>
                                                                    setSearchTerm(
                                                                        e.target
                                                                            .value
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    ) : (
                                                        <div className="ps-2 pe-sm-5 pe-2 py-3 text-center">
                                                            <h6 className="bold">
                                                                No Subscribers
                                                            </h6>
                                                        </div>
                                                    )}
                                                </div>
                                            )}

                                            {activeTab === "tier" && (
                                                <div className="tab-contents">
                                                    <p>Tier: $99</p>
                                                    <p>
                                                        <strong>
                                                            Tier: $34
                                                        </strong>
                                                    </p>
                                                    <p>Tier: $23</p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="membersOverview">
                            {participants
                                ?.filter((e) =>
                                    e?.user?.email
                                        ?.toLowerCase()
                                        ?.includes(searchTerm.toLowerCase())
                                )
                                ?.filter((e, index) => {
                                    if (
                                        index < page * 10 &&
                                        index > page * 10 - 11
                                    ) {
                                        return e;
                                    }
                                })
                                ?.map((filteredParticipant) => (
                                    <div
                                        className="card memberContent align-items-lg-center align-items-md-center"
                                        key={filteredParticipant.user._id}
                                    >
                                        <div className="contentBlock align-items-end width-90">
                                            <div className="content width-60">
                                                <h5>
                                                    {
                                                        filteredParticipant
                                                            ?.user?.email
                                                    }
                                                </h5>
                                                <p className="mt-2">
                                                    {`Account size: $${simplifyTradingVolume(
                                                        (filteredParticipant
                                                            ?.binanceBalance
                                                            ?.balance || 0) + (filteredParticipant
                                                                ?.binanceBalance
                                                                ?.coinFutureBalance ||
                                                                0)
                                                    )}`}
                                                </p>
                                            </div>
                                            <div className="content">
                                                <p>Joined: {(() => { const d = new Date(filteredParticipant.date); return `${d.getMonth() + 1}.${d.getDate()}.${d.getFullYear()}`; })()}</p>
                                                {/* <h5 className="mt-2 text-end">Tier: $213</h5> */}
                                            </div>
                                        </div>

                                        <div className="number">
                                            <img
                                                onClick={() => {
                                                    let _community =
                                                        filteredParticipant.user.memberCommunities?.find(
                                                            (f) =>
                                                                f.community ===
                                                                leaderCommunity._id.toString()
                                                        );

                                                    setSelectedSubscription(
                                                        _community.sessionId
                                                    );
                                                    setSelectedUser(
                                                        filteredParticipant.user
                                                            ._id
                                                    );
                                                    setShowModal(true);
                                                }}
                                                src={Cross}
                                                alt="cross"
                                            />
                                            <img
                                                src={Message}
                                                alt="message"
                                                onClick={() =>
                                                    handleMessage(
                                                        filteredParticipant?.user
                                                    )
                                                }
                                            />
                                        </div>
                                    </div>
                                ))}
                        </div>
                        {filterData?.length > 0 ? (
                            <div className="d-flex ms-auto justify-content-end mt-41">
                                <Pagination
                                    setPage={setPage}
                                    page={page}
                                    items={participants}
                                    filtered={filteredParticipants}
                                />
                            </div>
                        ) : filterData?.length == 0 ? (
                            <div className="text-center">
                                <img src={NoDataGif} alt="no data" height={180} />
                                <h1 className="fs20 bold my-3">No results found</h1>
                            </div>
                        ) : ""
                        }

                    </div>
                </div>
            </div>
            {showModal && (
                <DeleteMember
                    noAction={noAction}
                    yesAction={yesAction}
                    text="Delete member?"
                />
            )}
            <RenderLinks page="membersOverview" />
        </div>
    );
}

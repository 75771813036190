// InputComponent.js
import React, { useRef, useState } from "react";
import open_icon from "../../assets/images/View.svg";
import close_icon from "../../assets/images/noView.svg";

// Modify the InputComponent to handle password visibility
const InputComponent = ({
    type,
    label,
    focusedInput,
    handleFocus,
    handleBlur,
    value,
    onChange,
}) => {
    const inputRef = useRef();
    const [showPassword, setShowPassword] = useState(false);

    return (
        <div className={`form-input ${focusedInput === type ? "focused" : ""}`}>
            {type === "password" ? (
                <>
                    <input
                        type={showPassword ? "text" : "password"}
                        placeholder={label}
                        required
                        ref={inputRef}
                        onFocus={() => handleFocus(type)}
                        onBlur={handleBlur}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        autoComplete="new-password"
                    />
                    <label
                        onClick={() => {
                            handleFocus(type);
                            inputRef?.current?.focus();
                        }}
                        htmlFor={type}
                    >
                        {label}
                    </label>
                    <div
                        className="password-toggle"
                        onClick={() => setShowPassword(!showPassword)}
                    >
                        {showPassword ? (
                            <img src={close_icon} alt="open Icon" />
                        ) : (
                            <img src={open_icon} alt="close Icon" />
                        )}
                    </div>
                </>
            ) : (
                <>
                    <input
                        type={type}
                        placeholder={label}
                        required
                        ref={inputRef}
                        onFocus={() => handleFocus(type)}
                        onBlur={handleBlur}
                        value={value}
                        onChange={(e) => onChange(e.target.value)}
                        autoComplete="new-field"
                    />
                    <label
                        onClick={() => {
                            handleFocus(type);
                            inputRef?.current?.focus();
                        }}
                        htmlFor={type}
                    >
                        {label}
                    </label>
                </>
            )}
        </div>
    );
};

export default InputComponent;

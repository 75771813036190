import React from 'react';
import NoDataGif from "../../../assets/images/no_data.gif"

const PayoutHistory = () => {
    return (
        <div className='centered-up'>
            <div className="centered">
                <img src={NoDataGif} alt="no data" height={180} />
                <h1 className="fs20 bold my-3">There's no payout history yet</h1>
            </div>
            </div>

        // <div className="text-center">
        //     <img src={NoDataGif} alt="no data" height={180} />
        //     <h1 className="fs20 bold my-3">There's no payout history yet</h1>
        // </div>
    )
}

export default PayoutHistory;